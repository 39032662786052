require('./report-box.scss');

class ReportBox {

  constructor(){

    this.options = {
      placement: 'bottom-left',
      popupCloseDelay: 1000,
      class: 'customClass'
    };
  }

  // injection here
  static get $inject() {
    return [
    ];
  }
  
  sectionStyle(section) {
    let style = {'background-color': section.color, 
                  width: (section.percentage && section.percentage != 0 ? section.percentage : '0') + '%'};
    if (this.height) {
      style['line-height'] = this.height + 'px';
    }
    return style;
  }

  boxStyle() {
    let style = {};
    if (this.width) {
       style.width = (this.width + 'px');
    }
    if (this.height) {
      style.height = (this.height + 'px');
    }
    return style;
  }

  detailsStyle() {
    let style = {};
    if (this.height) {
      style['line-height'] = (this.height + 'px');
    }
    return style;
  }

  compareBoxStyle() {
    let style = {};
    if (this.width) {
       style.width = (this.width + 'px');
    }
    return style;
  }
  
  $onInit() {
    return (() => {
      this.sCompareSections = this.sCompareSections ? this.sCompareSections : [];
      this.width = this.width ? this.width : 250;
    })();
  }

  $onChanges(changes) {
    if (changes.sCompareSections && !changes.sCompareSections.isFirstChange()) {
      // console.log('Changes scomparesections:',changes)
    }
    if (changes.sInfo && !changes.sInfo.isFirstChange()) {
      // console.log('Changes sinfo:',changes)
    }
  }
}

module.exports = {
  template: require('./report-box.html'),
  transclude: true,
  controllerAs: 'ctrl',
  controller: ReportBox,
  bindings: {
    mainSections: '<sSections',
    compareSections: '<?sCompareSections',
    info: '<?sInfo',
    // minimize: '@?',
    width: '@?sWidth',
    height: '@?sHeight'
  }
};