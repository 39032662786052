module.exports = [ '$compile', '$timeout', 'events', 'qtiLoader',
  function ($compile, $timeout, events, qtiLoader) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        templatePath: '=',
        onSelection: '=qtiOnSelection',
        model: '='
      },
      link: function(scope, element, attrs)
      { 
        // transform qti xml into html with valid directives (using xslt)
        const result = qtiLoader.loadQti(scope.templatePath);

        //console.log('Transformed qti:',result);//result.firstChild);

        // TODO if the templatePath is not present at init we need a watch in the property to transform when it's present

        element.html(result);
        $timeout(function() {
          $compile(element.contents())(scope);
        })
        
        // listen for UPDATE_MODEL events from the inner question element
        element[0].addEventListener(events.UPDATE_MODEL, ev => {
          const responses = [];
          // console.log('UPDATE_MODEL event:',ev);

          ev.detail.forEach(d => {
            if (d.identifier) {
              responses.push({identifier: d.identifier, value: d.candidateResponse});
            }
          });

          scope.model = responses;

          if (scope.onSelection) {
            scope.onSelection(scope.model);
          }
        });
      }
    };
}];