/* eslint-disable max-len */
module.exports = ['constants', 'settings', '$oauth', 'sriClient', 'Utils', 'dateUtils', 'dataHelpers',
  function (constants, settings, $oauth, sriClient, Utils, dateUtils, dataHelpers) { //eslint-disable-line

    const {
      apiHelpers,
      dataProcessingHelpers
    } = dataHelpers;

    const service = { data: {} };

    const samConfig = {
      baseUrl: settings.apisAndUrls.samApi.replace('-.', '-prod.'),
      headers: {},
      logging: true,
      prefix: settings.apisAndUrls.samApi.indexOf('/sam') !== -1 ? '/sam' : ''
    };

    samConfig.headers[settings.samenscholing.name] = settings.samenscholing.token;

    /**
   * Get from vsko api the responsibilities of the current user as the preventie advisor
   */
    service.getPreventionAdvisorResponsibilities = async () => {
      const me = await $oauth.getMe();

      if (!me) {
        return [];
      }

      // 1. get official schools where the PA is working
      const respPA = await sriClient.getAll('/responsibilities', { persons: '/persons/' + me.uuid, positions: constants.preventionAdvisorPosition.href, date: dateUtils.getNow() });
      const respHeadPA = await sriClient.getAll('/responsibilities', { persons: '/persons/' + me.uuid, positions: constants.headPreventionAdvisorPosition, date: dateUtils.getNow() });
      console.log('Resp as PA and headPA:', respPA.length, respHeadPA.length);
      return respPA.concat(respHeadPA);
    };

    const getPersRespsAsEmployee = async (me) => {
      // Get schools where the "preventie advisor" is working as employee
      return sriClient.getAll('/responsibilities', {
        persons: '/persons/' + me.uuid,
        positions: constants.preventionAdvisorPosition.href,
        date: dateUtils.getNow()
      });
    };

    const getPersRespsAsDirector = async (me) => {
      // Get schools where the "preventie advisor" is working as director
      return sriClient.getAll('/responsibilities', {
        persons: '/persons/' + me.uuid,
        positions: constants.headPreventionAdvisorPosition,
        date: dateUtils.getNow()
      });
    };

    /**
     * In PRIMARY education the responsabilities of the Preventie Advisors are not migrated to the SAM API yet,
     * so they remain on VOS API.
     * That means that the responsabilities are hold in a "concept" called OfficialSchool. Those OfficialSchools
     * are basically "virtual partitions" of a "real School" / SchoolEntity. So, for one
     * "real School" / SchoolEntity, you could have one or more OfficialSchools.
     *
     * Because of that, in order to process the PRIMARY schools, we need:
     *  1. Get the data of those OfficialSchools from VOS API (old API)
     *  2. From the properties of those OfficialSchools, get the related "real Schools" / SchoolEntities
     *  3. Get the SAM data for those "real Schools" / SchoolEntities
     *    3.1 We need to keep the hrefs of the officialSchools related to every SchoolEntity we get
     *        in order to be able to recover the "directors" of those SchoolEntities later
     *  4. Get the Governing Institutions SAM data for the officialSchools we have
     *    4.1 In this case we don't need to call any API to get the hrefs from those because the
     *      Governing Institutions for an OfficialSchool are added in the VOS API response
     *
     * VOCABULARY SUMMARY:
     *  - OfficialSchool:
     *    - "Virtual School", that represents a part of a "real School" / SchoolEntity
     *    - The same OfficialSchool could belong to "N" "real Schools" / SchoolEntities
     *    - Even that it could be confusing in the API the type of those is "SCHOOL"
     *  - SchoolEntity:
     *    - Those are the "real Schools", meaning, the schools that the people know, "the building"
     *    - They can be splitted in multiple parts called "OfficialSchools"
     *    - For those the API type is "SCHOOLENTITY"
     * @param {*} responsabilities
     * @param {*} includeEnded
     * @returns
     */
    const getPrimarySchools = async (responsabilities, includeEnded) => {
      // Only PRIMARY schools href should start with `/schools`.
      // Even so it is possible that we have some reminiscences from the past but, if that is so,
      // they will be cleaned in the next steps
      const officialSchoolsHrefs = responsabilities
        .filter(responsibility => responsibility.organisation.href.indexOf('/schools') !== -1)
        .map(responsibility => responsibility.organisation.href);

      if (officialSchoolsHrefs.length === 0) {
        return [];
      }

      const officialSchoolsResp = await sriClient.getAllHrefs(officialSchoolsHrefs);
      // Even that CVOs and SECONDARY schools hrefs should not start with `/schools`
      // we should have some reminiscences from the past, so we need to cleanup the array
      const officialSchools = officialSchoolsResp.filter(school => school.$$meta.type === 'SCHOOL' && school.educationLevel === 'BASIS');

      if (officialSchools.length === 0) {
        return [];
      }

      // First we get the schools
      const primarySAMSchools = await officialSchools.reduce(async (prevOfficialSchoolsPromise, currOfficialSchool) => {
        // wait for previous execution to finish (using async/await with arr.reduce...)
        const prevOfficialSchools = await prevOfficialSchoolsPromise;

        // Get the SAM href for an officialSchool
        const SAMOfficialSchoolHref = await apiHelpers.SAMAPI.getHrefFromInstNum(currOfficialSchool.institutionNumber);

        // Get the SAM data from those schools
        const SAMSchoolEntities = await apiHelpers.SAMAPI.getRelatedSchoolEntities(SAMOfficialSchoolHref, 'PRIMARY');

        // we need to save the OfficialSchools href in the SAM data in order to be able to find
        // the "directors" later
        SAMSchoolEntities.forEach((s) => {
          // It could be possible that a user works in two different "OfficialSchools" that belongs
          // to the same "SchoolEntity"
          // If that is the case we need to remove duplicates but we need to keep the hrefs of
          // the "OfficialSchools".
          // So, we will have unique "SchoolEntities" in the array but every "SchoolEntity" will
          // be able to have more than one `officialSchool`
          // This is needed to be able to get the "directors" for each SchoolEntity later
          const duplicatedSchoolEntity = prevOfficialSchools.find(x => x.key === s.key);
          const officialSchoolHref = currOfficialSchool.$$meta.permalink;
          if (duplicatedSchoolEntity) {
            duplicatedSchoolEntity.schoolsHrefsForGettingDirectors.push(officialSchoolHref);
          } else {
            prevOfficialSchools.push({
              ...s,
              schoolsHrefsForGettingDirectors: [officialSchoolHref]
            });
          }
        });

        return prevOfficialSchools;
      }, Promise.resolve([]));

      // then we get the "Governing Institutions" related to those schools
      const primarySAMGovInsts = await apiHelpers.VOSAPI.getSAMGovInstFromVOSSchools(officialSchools, 'GOVERNING_INSTITUTION', includeEnded);

      const primarySchools = [...primarySAMSchools, ...primarySAMGovInsts];

      console.log('PRIMARY Data: ', {
        officialSchools,
        primarySchools
      });

      return primarySchools;
    };


    /**
     * In SECONDARY education the responsabilities of the Preventie Advisors are already migrated to the
     * SAM API.
     * Because of that we only need to get the SAM elements for the SECONDARY schools and we will be able
     * to get the directors from there
     * @param {*} responsabilities
     * @returns
     */
    const getSecondarySchools = async (responsabilities) => {
      const secondarySamSchoolHrefs = responsabilities
        .filter(responsibility => responsibility.organisation.href.indexOf('/sam/organisationalunits') !== -1)
        .map(responsibility => responsibility.organisation.href);

      if (secondarySamSchoolHrefs.length === 0) {
        return [];
      }

      // first we get the schools
      const secondarySamSchools = await apiHelpers.SAMAPI.getElementsFromSam(secondarySamSchoolHrefs, 'SECONDARY');

      // then we get the "Governing Institutions" related to those schools
      const secondaryGovInsts = await apiHelpers.SAMAPI.getGovInstsForGivenSchoolsSAMHrefs(secondarySamSchoolHrefs, 'GOVERNING_INSTITUTION');

      const secondarySchools = [...secondarySamSchools, ...secondaryGovInsts];

      console.log('SECONDARY Data: ', {
        officialSchools: 'SECONDARY DOESN\'T HAVE OFFICIAL-SCHOOLS',
        secondarySchools
      });

      return secondarySchools;
    };

    /**
     * In CVO education the responsabilities of the Preventie Advisors are associated to the CVO representation
     * in the VOS API (old API).
     * Because of that, after getting the SAM elements for the CVO schools we need to keep the VOS API hrefs
     * in every processed element to be able to recover later the directors for each school
     * @param {*} responsabilities
     * @returns
     */
    const getCVOSchools = async (responsabilities) => {
      // In this case we get only the ones which the href starts with `/cvos`
      const CVOSVOSHrefs = responsabilities
        .filter(responsibility => responsibility.organisation.href.indexOf('/cvos') !== -1)
        .map(responsibility => responsibility.organisation.href);

      if (CVOSVOSHrefs.length === 0) {
        return [];
      }

      const CVOSVOS = await sriClient.getAllHrefs(CVOSVOSHrefs);

      const CVOSAMResp = await Promise.all(
        CVOSVOS.map(async (CVOVOS) => {
          const SAMHref = await apiHelpers.SAMAPI.getHrefFromInstNum(CVOVOS.institutionNumber);
          const SAMSchools = await apiHelpers.SAMAPI.getElementsFromSam([SAMHref], 'CVO');
          // This is a special case where we need to set up in `schoolsHrefsForGettingDirectors` the
          // VOS end-point instead of the SAM one
          SAMSchools.forEach(x => { (x.schoolsHrefsForGettingDirectors = [CVOVOS.$$meta.permalink]); });
          return SAMSchools;
        })
      );

      // first we get the schools
      const CVOSAMSchools = CVOSAMResp.flat();

      // then we get the "Governing Institutions" related to those schools

      // In CVO we don't call the API to get the "Governing Institutions" because the data from them is already
      // present in the API response that we already fetched when we got the data from the OfficialSchools
      const CVOSAMGovInsts = await apiHelpers.VOSAPI.getSAMGovInstFromVOSSchools(CVOSVOS, 'GOVERNING_INSTITUTION');

      const CVOSchools = [...CVOSAMSchools, ...CVOSAMGovInsts];

      console.log('CVOS Data: ', {
        CVOSVOS,
        CVOSchools
      });

      return CVOSchools;
    };

    const getBoardingSchools = async (responsabilities) => {
      const boardingsVOSHrefs = responsabilities
        .filter(responsibility => responsibility.organisation.href.indexOf('/boarding') !== -1)
        .map(responsibility => responsibility.organisation.href);

      if (boardingsVOSHrefs.length === 0) {
        return [];
      }

      const boardingsVOS = await sriClient.getAllHrefs(boardingsVOSHrefs);

      const boardingsSAMResp = await Promise.all(
        boardingsVOS.map(async (boardingVOS) => {
          const SAMHref = await apiHelpers.SAMAPI.getHrefFromInstNum(boardingVOS.institutionNumber);
          const SAMSchools = await apiHelpers.SAMAPI.getElementsFromSam([SAMHref], 'BOARDING');
          // This is a special case where we need to set up in `schoolsHrefsForGettingDirectors` the
          // VOS end-point instead of the SAM one
          SAMSchools.forEach(x => { (x.schoolsHrefsForGettingDirectors = [boardingVOS.$$meta.permalink]); });
          return SAMSchools;
        })
      );

      // first we get the schools
      const boardingSAMSchools = boardingsSAMResp.flat();

      // then we get the "Governing Institutions" related to those schools

      // In Boarding Schools we don't call the API to get the "Governing Institutions" because the data from them is already
      // present in the API response that we already fetched when we got the data from the OfficialSchools
      const boardingSAMGovInsts = await apiHelpers.VOSAPI.getSAMGovInstFromVOSSchools(boardingsVOS, 'GOVERNING_INSTITUTION');

      const boardingSchools = [...boardingSAMSchools, ...boardingSAMGovInsts];

      console.log('boardingSchools Data: ', {
        boardingsVOS,
        boardingSchools
      });

      return boardingSchools;
    };

    const getSchoolEntitiesUserIsDirectorOf = async (responsabilities) => {
      const govInstsUserIsDirectorOfHrefs = responsabilities
        .filter(responsibility => responsibility.organisation.href.indexOf('/sam/organisationalunits') !== -1)
        .map(responsibility => responsibility.organisation.href);

      if (govInstsUserIsDirectorOfHrefs.length === 0) {
        return [];
      }

      // STEPS:
      //  1. Get the School Entities where the user is the director of their Governing Institution
      //  2. Add the Governing Institutions where he is the director to the general array

      // Getting the relations between the `Governing Institutions`, where the user is the director,
      // and the School Entitities
      // This gives us two pieces of informtion:
      //  - The School Entities data (in the `to` relation)
      //  - The Governing Institutions data (in the `from` relation)
      const govInstToSchoolEntsRelations = await sriClient.getAll('/organisationalunits/relations', {
        type: 'GOVERNS',
        from: govInstsUserIsDirectorOfHrefs.join(','),
        'to.type': 'SCHOOLENTITY',
        expand: 'results.to,results.from',
        status: 'ACTIVE'
      }, samConfig);

      if (govInstToSchoolEntsRelations.length === 0) {
        return [];
      }

      // STEP 1: Related School Entities
      const schoolEntsUserIsDirectorOfGovInst = await Promise.all(
        govInstToSchoolEntsRelations.map(async (schoolEntityRel) => {
          const schoolEntity = schoolEntityRel.to.$$expanded;
          const govInst = schoolEntityRel.from.$$expanded;

          // We get the related Official School for a the School Entity
          const officialSchool = await apiHelpers.VOSAPI.getVOSOffSchoolFromSAMSchoolEntHref(schoolEntity.$$meta.permalink);

          if (!officialSchool) {
            console.error(`The schoolEntity provided (${schoolEntity.$$meta.permalink}) is not linked to an officialSchool (corrupted data: contact core-data team)`);
            return undefined;
          }

          const isPrimary = officialSchool.educationLevel === 'BASIS';
          const level = (isPrimary && 'PRIMARY') || 'SECONDARY';

          const processedData = dataProcessingHelpers.schools.extractDataFromSAMEl(schoolEntity, level);

          // If the school is:
          //  - PRIMARY => we set up the VOS urls in `schoolsHrefsForGettingDirectors`
          //  - SECONDARY => we just leave the ones that came from `extractDataFromSAMEl` (SAM hrefs)
          if (isPrimary) {
            return {
              ...processedData,
              schoolsHrefsForGettingDirectors: [officialSchool.$$meta.permalink],
              governingInstitutionHref: govInst.$$meta.permalink
            };
          }
          return processedData;
        })
      ).then((resp) => resp.filter(x => x));

      // STEP 2: Governing Institutions

      // we do the following "uniqueBy" because in these results `govInstToSchoolEntsRelations` the
      // `from` property will be duplicted for every SchoolEntity that belongs to the same Governing Institution
      const govInstsUserIsDirectorOfRaw = Utils.uniqueBy(govInstToSchoolEntsRelations.map(relation => relation.from.$$expanded), 'key');

      const govInstsUserIsDirectorOf = govInstsUserIsDirectorOfRaw.map(govInst => {
        // We use the list of the Governing Institution related SchoolEntities to get the `schoolsHrefsForGettingDirectors`
        // that we will have available to use for this Governing Institution
        const schoolsHrefsForGettingDirectors = schoolEntsUserIsDirectorOfGovInst
          .filter(x => x.governingInstitutionHref === govInst.$$meta.permalink)
          .map(s => s.schoolsHrefsForGettingDirectors)
          .flat();

        const processedGovInst = dataProcessingHelpers.schools.extractDataFromSAMEl(govInst, 'GOVERNING_INSTITUTION');
        return {
          ...processedGovInst,
          schoolsHrefsForGettingDirectors
        };
      });

      console.log('SchoolEntitiesUserIsDirectorOf', {
        schoolEntsUserIsDirectorOfGovInst,
        govInstsUserIsDirectorOf
      });

      const schoolEntitiesUserIsDirectorOf = [...schoolEntsUserIsDirectorOfGovInst, ...govInstsUserIsDirectorOf];
      return schoolEntitiesUserIsDirectorOf;
    };

    service.getRelevantOrgUnitsForUser = async (includeEnded = false) => {
      if (service.data.allSchools) {
        // already loaded previously, don't request all again, it will not change
        return !includeEnded ? dateUtils.getActiveResources(service.data.allSchools) : service.data.allSchools;
      }

      const me = await $oauth.getMe();
      // 1. get official schools where the PA is working
      const persRespsAsEmployee = await getPersRespsAsEmployee(me);

      // load primary schools (schoolentity) from samenscholing
      const primarySchoolsEntities = await getPrimarySchools(persRespsAsEmployee, includeEnded);
      // load secondary schools as school entities
      const secondarySchoolsEntities = await getSecondarySchools(persRespsAsEmployee);
      // load cvos from samenscholing
      const CVOSchoolsEntities = await getCVOSchools(persRespsAsEmployee);
      // load boarding schools
      const boardingSchoolEntities = await getBoardingSchools(persRespsAsEmployee);

      // 2. get sam governing institutions where the user is working as Director
      const persRespsAsDirector = await getPersRespsAsDirector(me);

      const schoolEntitiesUserIsDirectorOf = await getSchoolEntitiesUserIsDirectorOf(persRespsAsDirector);

      const relevantUserOUs = [
        ...primarySchoolsEntities,
        ...secondarySchoolsEntities,
        ...CVOSchoolsEntities,
        ...boardingSchoolEntities,
        ...schoolEntitiesUserIsDirectorOf
      ];

      // Here we can have duplicates for some different reasons:
      //  - the user is a PA of a schools and the director of the Governing Institution
      //  - the user is a PA of a Primary School and a Secondary School of the same Governing Institution
      //  - ...
      // So we delete the duplicated, but we keep the values inside the `schoolsHrefsForGettingDirectors` of
      // both because we are going to need them when calculating the directors

      service.data.allSchools = relevantUserOUs.reduce((prev, curr) => {
        const duplicatedOU = prev.find(x => x.key === curr.key);
        if (duplicatedOU) {
          const mergedSchoolsHrefsForGettingDirectors = [...new Set([
            ...duplicatedOU.schoolsHrefsForGettingDirectors,
            ...curr.schoolsHrefsForGettingDirectors
          ])];
          duplicatedOU.schoolsHrefsForGettingDirectors = mergedSchoolsHrefsForGettingDirectors;
        } else {
          prev.push(curr);
        }

        return prev;
      }, []);

      // load the address for each school entity calling /contactdetails and physical locations
      service.data.allSchools.forEach(async se => {
        se.address = await service.getAddress(se);

        service.getPhysicalLocations(se).then(locations => {
          if (locations.length > 1) {
            console.log('Physical locations of se: ', se, locations);
          }
          locations.forEach(location => {
            location.display = Utils.getLocationAddress(location.physicalLocation.$$expanded);
          });
          se.locations = locations;
        });
      });

      console.log('All School Entities:', service.data.allSchools);

      return service.data.allSchools;
    };

    service.getAddress = async (se) => {
      const contactdetails = await sriClient.getAll('/organisationalunits/contactdetails', { organisationalUnit: se.$$meta.permalink, type: 'MAIL' }, samConfig);
      return Utils.getOrganisationalUnitAddress(
        contactdetails.length > 0 ? contactdetails[0] : null
      );
    };

    service.getByHref = async (href) => {
      return sriClient.get(href, null, samConfig);
    };

    /**
   * Try to get the given organisational unit from the ones already loaded
   * (those linked to the current PA)
   * It includes school entities and gov institutions.
   */
    service.getLoadedOrganisationalUnit = async (href, includeEnded) => {
      const prevAdvSchools = await service.getRelevantOrgUnitsForUser(includeEnded);
      const ois = prevAdvSchools.filter(
        se => se.$$meta.permalink === href || se.$$meta.permalink.replace('/sam/', '/') === href
      );
      return ois.length > 0 ? ois[0] : undefined;
    };

    service.getPhysicalLocations = (se) => {
      return sriClient.getAll('/organisationalunits/locations',
        {
          organisationalUnit: se.$$meta.permalink,
          expand: 'results.physicalLocation',
          status: 'ACTIVE',
          type: 'CAMPUS'
        },
        samConfig);
    };

    /**
   * Get all the directors under the governing insitution (official schools)
   * where the given school entity is part
   *
   * @param se: school entity
   */
    service.getDirectors = async (se) => {
      let directorPositionsHrefs;
      const schoolsHrefsForGettingDirectors = [ ...se.schoolsHrefsForGettingDirectors ];
      switch (se.type) {
        case 'BOARDING': directorPositionsHrefs = [constants.administratorPosition.href];
          break;
        case 'GOVERNINGINSTITUTION': directorPositionsHrefs = [
            constants.administratorPosition.href,
            constants.directorPosition.href,
            constants.CEOPosition.href
          ];
          // In Governing Institutions we want the directors from the governing institution itself
          schoolsHrefsForGettingDirectors.push(se.$$meta.permalink);
          break;
        default: directorPositionsHrefs = [constants.directorPosition.href];
      }
      return service.getPersonsInSchoolEntityWithPosition(schoolsHrefsForGettingDirectors, directorPositionsHrefs);
    };

    /**
     * Get all the persons working in the school entity with given position.
     * If it's secondary we can use the se href but if it's primary or cvo we need to use the offical school href
     *
     * @param schoolsHrefsForGettingDirectors: array
     * @param positionsHref: array
     */
    service.getPersonsInSchoolEntityWithPosition = async (schoolsHrefsForGettingDirectors, positionsHref) => {
      let persons = [];

      try {
        // 1. get official schools under the governing institution
        // Depending on the level of the school the `schoolsHrefsForGettingDirectors` will include
        // different types of hrefs:
        //  - PRIMARY: It will include an array of "OfficialSchools" VOS API (old API) hrefs
        //  - SECONDARY: It will include an array with only one element including the SchoolEntity
        //    SAM API href
        //  - CVO: It will include an array with only one element including the CVO VOS API (old API) href
        //  - BOARDINGS: It will include an array with only one element including the BOARDING VOS API (old API) href
        console.log('schoolsHrefsForGettingDirectors', schoolsHrefsForGettingDirectors);

        if (schoolsHrefsForGettingDirectors.length) {
        // 2. get the directors from all the official schools under the gov inst
          const resps = await sriClient.getAll('/responsibilities', {
            organisations: schoolsHrefsForGettingDirectors.join(','),
            positions: positionsHref.join(','),
            date: dateUtils.getNow()
          });

          if (resps && resps.length) {
            // 3. expand /persons
            persons = await sriClient.getAll('/persons', {
              hrefs: resps.map(r => r.person.href).join(','),
              omit: 'dateOfBirth,registrationNumber'
            });

            persons = persons.filter(person => !person.firstName.match(/^Demo\s(directeur|leerkracht)\s[a-z]/));

            // sort the persons list by last name
            persons = persons.sort((p1, p2) => {
              if (p1.lastName === p2.lastName) {
                return p1.fisrtName > p2.firstName ? 1 : -1;
              }
              return p1.lastName > p2.lastName ? 1 : -1;
            });
          }
        }
      } catch (e) {
        console.log('Error trying to get persons:', e);
      }

      return persons;
    };

    service.getAllPreventieAdvisorMails = async (se, currentUser) => {
      const schoolPreventionAdvisors = await service.getPersonsInSchoolEntityWithPosition(se.schoolsHrefsForGettingDirectors, [constants.preventionAdvisorPosition.href]);
      const recipients = schoolPreventionAdvisors.map(pa => pa.$$email);
      if (!recipients.includes(currentUser.$$email)) {
        // for the case of the head preventie advisor who is not part of the school
        recipients.push(currentUser.$$email);
      }
      return recipients;
    };

    return service;
  }];
