module.exports = {
    REGISTER_FEEDBACK: 'registerFeedback',
    REGISTER_INTERACTION: 'registerInteraction',
    REGISTER_STATUSELEMENT: 'registerStatus',
    REGISTER_CHOICE: 'registerChoice',
    OUTCOME_CHANGED: 'outcomeChanged',
    SUBMIT_RESPONSES: 'submitResponses',
    PROCESSED_RESPONSES: 'processedResponses',
    SHOW_ANSWERS: 'showAnswers',
    SAVE_RESPONSE: 'saveResponse',
    CHOICE_SELECTED: 'choiceSelected',
    DROPDOWN_SELECTED: 'dropdownSelected',
    CHOICE_ELEMENT_SELECTED: 'choiceElementSelected',
    UPDATE_UI: 'updateUI',
    FLIP_HANDLER: 'flipHandler',
    DISABLE_CHECKBOX: 'disableCheckbox',
    ANSWER_CHANGED: 'answerChanged',
    DOM_READY: 'domReady',
    SUB_DOM_READY: 'subDomReady',
    REGISTER_GAP: 'registerGap',
    LOADED_CHOICE: 'loadedChoice',
    LOADED_TEXTENTRY: 'loadedTextentry',
    LOADED_ORDERLIST: 'loadedOrderList',
    LOADED_MATCHLIST: 'loadedMatchList',
    LOADED_DROPDOWNCHOICE: 'loadedDropdownChoice',
    UPDATE_RESPONSES: 'updateResponses',
    UPDATE_MODEL: 'updateModel'
}