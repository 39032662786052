/* eslint-disable max-len */
/* eslint-disable func-names */
module.exports = ['$q', '$rootScope', '$location', 'constants', 'settings', 'dateUtils', '$oauth', 'sriClient', 'Utils', function ($q, $rootScope, $location, constants, settings, dateUtils, $oauth, sriClient, Utils) { //eslint-disable-line

  'use strict';

  var service = {};

  service.data = { userSchools: [] };

  const samConfig = {
    baseUrl: settings.apisAndUrls.samApi.replace('-.', '-prod.'),
    headers: {
      // 'SAM-ACCESSTOKEN': '76fadd5f-2a07-11e5-be0a-00ffa0598608'
    },
    logging: true
  };

  samConfig.headers[settings.samenscholing.name] = settings.samenscholing.token;

  /**
 * Set the schools where the logged prevention user works. Will be useful for school selection screen.
 */
  // @deprecated
  service.setUserSchools = function (schools) {
    service.data.userSchools = schools;
  };

  // @deprecated
  service.getUserSchools = function (key) {
    return service.data.userSchools;
  };

  function getPreventionAdvisorSchoolsHrefs() {
    const d = $q.defer();

    $oauth.getMe().then(me => {
      const url = '/responsibilities?persons=/persons/' + me.uuid + '&positions=' + constants.preventionAdvisorPosition.href;

      sriClient.getAll(url).then(results => {
        const schoolsHref = results.map(responsibility => responsibility.organisation.href);

        d.resolve(schoolsHref);
      });
    });

    return d.promise;
  }

  /**
 * To get the prevention advisor schools we check in /responsibilities
 * Should have a valid 'prevention-advisor' position
 * @return: array of school hrefs
 */
  service.getPreventionAdvisorSchools = function () {
    const d = $q.defer();

    getPreventionAdvisorSchoolsHrefs().then(hrefs => {
      d.resolve(hrefs);
    });

    return d.promise;
  };

  /**
 * Includes schools + governing institutions
 */
  service.getPreventionAdvisorSchoolsObjects = function () {
    const d = $q.defer();

    getPreventionAdvisorSchoolsHrefs().then(hrefs => {
      let organisations = [];

      let promises = hrefs.map(schoolHref => {
        return sriClient.get(schoolHref);
      });

      $q.all(promises).then(results => {
        promises = [];

        organisations = results.map(school => {
          // load the governing instiutition of the school also as an option
          if (school.$$governingInstitutions && school.$$governingInstitutions.length > 0) {
            school.$$governingInstitutions.map(govInst => {
              promises.push(sriClient.get(govInst.href));
            });
          }

          return school;
        });

        $q.all(promises).then(results => {
          results.forEach(govInst => {
            organisations.push(govInst);
          });

          d.resolve(organisations);
        });
      });
    });

    return d.promise;
  };

  /**
 * Set the school the prevention advisor selected to be working with (may be the unique he has).
 * param: school eg. {key: , name: , address: }
 */
  service.setSchool = function (school) {
    service.data.school = school;
  };

  /**
 * School eg. {key: , name: , address: }
 */
  service.getSchool = function () {
    if (!service.data.school) {
      return;
    }
    return service.data.school.$$expanded ? service.data.school.$$expanded : service.data.school;
  };

  service.isSchoolLoaded = function () {
    return service.data.school;
  };

  /**
 * Set the physical location where the survey is created for (depends on the selected school)
 */
  service.setPhysicalLocation = function (location) {
    service.data.location = location;
  };

  /**
 * Get the physical location chosen for the current survey
 */
  service.getPhysicalLocation = function () {
    if (!service.data.location) {
      return;
    }
    return service.data.location.$$expanded ? service.data.location.$$expanded : service.data.location;
  };

  service.setDirectors = function (directors) {
    service.data.directors = directors;
  };

  service.getDirectors = function () {
    return service.data.directors ? service.data.directors : [];
  };

  /**
* Get the logged user survey if initialized
*/
  service.getSurvey = function (school) {
    const d = $q.defer();

    if (school && school.key) {
      service.setSchool(school);
    }

    if (service.data.survey && service.data.survey.key) {
      d.resolve(service.data.survey);
    } else if (service.data.school) {
      // try to load the survey resource for the selected school
      // const url = '/surveys?school=/schools/' + service.data.school.key;
      // sriClient.getAll(url).then(surveys => {
      //   service.data.survey = surveys.length > 0 ? surveys[0] : null;
      //   d.resolve(service.data.survey);
      // });
      d.resolve();
    } else {
      d.resolve();
    }

    return d.promise;
  };

  service.setSurvey = function (survey, school) {
    service.data.survey = survey;
    service.setSchool(school);
  };

  service.clearSurvey = function () {
    delete service.data.survey;
    delete service.data.school;
  };

  /**
 * Get all the surveys linked to the given schools
 *
 * @param {*} schools complete api resources
 */
  service.getSurveys = function (schools) {
    const d = $q.defer();

    // try to load the survey resource for the selected school
    const url = '/surveys?school.hrefsIn=' + schools.map(sch => sch.key.replace('/sam/', '/')).join(','); // TODO this replacing of /sam is because in wellbe api we dont store the prefix which is coming from school entity permalink
    // const url = '/surveys?school.hrefsIn=' + schools.map(sch => sch.key).join(',');
    sriClient.getAll(url).then(surveys => {
      service.data.userSurveys = surveys;

      surveys.forEach(s => {
        // get the answers of each user ended survey (will be used later in reports comparation)
        if (s.enddate && dateUtils.isAfter(dateUtils.stripTime(new Date().toISOString()), dateUtils.stripTime(s.enddate))) {
          service.getAnswers(s.key, null, false).then(answers => {
            s.answers = answers;
          });
        }

        // expand the physical location if present
        if (s.location && s.location.href) {
          sriClient.get(s.location.href, {}, samConfig).then(location => {
            s.location.display = Utils.getLocationAddress(location);
          });
        }
      });
      d.resolve(surveys);
    });

    return d.promise;
  };

  service.saveSurvey = function (survey) {
    service.data.survey = survey;
    // fix/clear some survey data before sending it: eg. group participants
    clearExtraData(survey);

    return sriClient.put('/surveys/' + survey.key, survey);
  };

  service.deleteSurvey = function (survey) {
    return sriClient.delete('/surveys/' + survey.key);
  };

  service.getCachedUserSurveys = function () {
    return service.data.userSurveys ? service.data.userSurveys : [];
  };

  /**
 * Send a mail to the director and chariman of the school. Will notify that the survey creation start.
 *
 * @param isWarningMail: type of message to send
 */
  service.sendDirectorsMail = function (intakeAnswers, isWarningMail, directors) {
    const d = $q.defer();

    // console.log('Directors mail data:',intakeAnswers, isWarningMail)

    let emailjob = $.extend({}, constants.generalTemplateEmail);
    let recipients = [];

    // add all directors and chairmans as recipients
    let persons = directors;

    let intakeQuestions = [];
    intakeAnswers.forEach(intake => {
      intakeQuestions.push(intake.question + ': ' + intake.answer);
    });

    const recipientEmails = [];

    persons.forEach(person => {
      if (!recipientEmails.includes(person.$$email)) {
        recipients.push({
          emailAddress: person.$$email,
          mergeVariables: [
            {
              name: 'POSITION',
              content: person.position
            },
            {
              name: 'USERNAME',
              content: $rootScope.user.username
            },
            {
              name: 'INTAKE_QUESTIONS',
              content: intakeQuestions
            }
          ]
        });
        recipientEmails.push(person.$$email);
      }
    });

    // NOTE: this is to avoid mailer-api duplicated recipients error (only needed when not sending real mails)
    if (settings.sendRealEmails === 'false' && recipients.length > 0) {
      recipients = [].concat(recipients[0]);
    }

    // create the body of the mail
    // TODO extract to mail template config file
    let bodyTemplate;

    if (isWarningMail) {
      bodyTemplate = constants.directorsBodyTemplateWarning;
    } else {
      bodyTemplate = constants.directorsBodyTemplate;
    }

    emailjob.key = Utils.getUUID();
    emailjob.content = {
      body: { href: bodyTemplate },
      frame: constants.generalTemplateEmail.content.frame
    };
    emailjob.recipients = recipients;

    if (recipients.length > 0) {
      sriClient.put('/mailer/emailjobs/' + emailjob.key, emailjob).then(() => {
        d.resolve();
      });
      console.log('New directors emailjob key:', emailjob.key);
    } else {
      d.resolve();
    }

    return d.promise;
  };

  /**
 * Send a mail to each of the given list of participants with the link to access to fill the survey.
 *
 * @param {*} participants
 * @param {*} emailtemplate
 * @param {*} survey
 */
  service.sendParticipantsMail = (participants, emailtemplate, survey) => {
    let recipients = [];

    let sendRealEmails = settings.sendRealEmails !== 'false';

    // hack to avoid problems with mailer-api, sending all mails to the same recipient is not possible -> send only one
    if (!sendRealEmails && participants.length > 1) {
      participants = participants.slice(0, 1);
    }

    // validate the body has the {{link}} and the subject is not empty
    if (emailtemplate.subject && emailtemplate.body.indexOf('{{link}}') !== -1) {
      participants.forEach(participant => {
        // let linkelement = '<a href="{{SURVEY_LINK}}" target="_blank">link</a>';
        let link = $location.protocol() + '://' + $location.host() + '/survey/#!/survey/' + survey.key
                + '?code=' + participant.key + '&school=' + survey.school.href
                + (survey.location ? '&location=' + survey.location.href : '');

        let linkelement = '<a href="' + link + '" target="_blank">link</a>';
        let body = emailtemplate.body
          .replace('{{link}}', linkelement)
          .replace('{{username}}', $rootScope.user.username);

        recipients.push({
          emailAddress: sendRealEmails ? participant.email : settings.adminEmail, // replace with participant.email
          mergeVariables: [
            {
              name: 'EMAIL',
              content: body
            }
          ]
        });
      });

      // replace body link variable
      let bodyTemplate = constants.participantsBodyTemplate;

      let emailjob = $.extend({}, constants.generalTemplateEmail);
      emailjob.key = Utils.getUUID();
      emailjob.content = {
        body: { href: bodyTemplate },
        frame: constants.generalTemplateEmail.content.frame
      };
      emailjob.subject = emailtemplate.subject;
      emailjob.recipients = recipients;
      emailjob.externalReferences = [{ href: '/surveys/' + survey.key }];

      console.log('New participants emailjob key:', emailjob.key);

      return sriClient.put('/mailer/emailjobs/' + emailjob.key, emailjob);
    }

    // not valid email to send
    let message = [];
    let d = $q.defer();

    if (!emailtemplate.subject) {
      message.push('Missing email subject (TODO translate)');
    }
    if (emailtemplate.body.indexOf('{{link}}') === -1) {
      // Missing {{link}} reference
      message.push('De link ontbreekt. Wil je de volgende tekst aan de uitnodiging toevoegen : Je kan de bevraging hier invullen: {{link}}');
    }
    d.reject({ message: message.join('. ') });

    return d.promise;
  };

  /**
 * There are many places where the preventie advisor will receive an email.
 * Depending on the given type it will create the corresponding email to send.
 */
  service.sendPreventieAdvisorMail = function (type, preventieAdvisorRecipients, survey, participant) {
    const d = $q.defer();

    let emailByType = constants.preventieAdvisorEmails.filter(e => e.type === type)[0];

    let emailjob = $.extend({}, constants.generalTemplateEmail);
    let recipients = [];

    let mergeVariables = [{
      name: 'SCHOOL_AND_LOCATIONS',
      content: this.getSchool().name + ' ' + Utils.getLocationAddresses(survey, this.getSchool())
    }];

    if (type === 'EDITED_PARTICIPANT_EMAIL' && participant) {
      mergeVariables.push({
        name: 'PARTICIPANT_EMAIL',
        content: participant.email
      });
    }

    preventieAdvisorRecipients.forEach(recipient => {
      recipients.push({
        emailAddress: recipient,
        mergeVariables: mergeVariables
      });
    });

    emailjob.key = Utils.getUUID();
    emailjob.subject = emailByType.subject + ' ' + this.getSchool().name;
    emailjob.content = {
      // body: { html: emailByType.body },
      body: { href: emailByType.bodyTemplate },
      frame: constants.generalTemplateEmail.content.frame
    };
    emailjob.recipients = recipients;


    sriClient.put('/mailer/emailjobs/' + emailjob.key, emailjob).then(() => {
      d.resolve();
    });
    console.log('New PA emailjob key:', emailjob.key);


    return d.promise;
  };

  /**
 * If it's the first survey created for the school a mail to the system administrator is sent
 */
  service.sendFirstSchoolSurveyMail = async function () {
    const d = $q.defer();

    let emailjob = $.extend({}, constants.generalTemplateEmail);
    let recipients = [];
    const school = this.getSchool();

    const me = await sriClient.get('/persons/' + $rootScope.user.key);

    recipients.push({
      emailAddress: 'wellbe@katholiekonderwijs.vlaanderen',
      mergeVariables: [
        {
          name: 'USERNAME',
          content: me.username
        },
        {
          name: 'SCHOOL_AND_LOCATIONS',
          content: school.name + ', ' + school.locations.map(l => l.display).join(' - ')
        },
        {
          name: 'DATE',
          content: Utils.toString(new Date())
        }
      ]
    });

    emailjob.key = Utils.getUUID();
    emailjob.subject = 'Check functie';
    emailjob.content = {
      body: { href: constants.firstSchoolSurveyBodyTemplate },
      frame: constants.generalTemplateEmail.content.frame
    };
    emailjob.recipients = recipients;

    if (recipients.length > 0) {
      sriClient.put('/mailer/emailjobs/' + emailjob.key, emailjob).then(() => {
        d.resolve();
      });
      console.log('First survey for school emailjob key:', emailjob.key);
    } else {
      d.resolve();
    }

    return d.promise;
  };

  /**
 * Check in /mailer api which emails of the given survey have status different to SENT|QUEUED
 * Returns and array list of bounced emails in a simple string format.
 *
 * @param {*} survey
 */
  service.getBouncedEmails = function (survey) {
    const d = $q.defer();

    let bouncedEmails = [];

    const emailsJobsUrl = '/mailer/emailjobs?externalReferences=/surveys/' + survey.key;

    sriClient.getAll(emailsJobsUrl).then(emailjobs => {
      if (emailjobs.length > 0) {
        let emailsUrl = '/mailer/emails?job=';

        emailsUrl += emailjobs.map(ej => ej.$$meta.permalink).join(',');

        sriClient.getAll(emailsUrl).then(emails => {
          emails.forEach(email => {
            if (email.status != 'SENT' && email.status != 'QUEUED') {
              bouncedEmails.push(email.recipient.emailAddress);
            }
          });

          d.resolve(bouncedEmails);
        });
      } else {
        d.resolve([]);
      }
    });

    return d.promise;
  };

  /**
 * Store the participant answers (/surveys/answers )
 * and update the submitted status of this participant in the survey (/surveys/:survey/status)
 */
  service.saveAnswers = function (questions, surveyKey, participantCode, participantGroupKey) {
    let body = {
      key: Utils.getUUID(),
      group: participantGroupKey,
      code: participantCode,
      survey: {
        href: '/surveys/' + surveyKey
      }
    };
    body.answers = [];
    questions.forEach(qst => {
      body.answers.push({
        before: qst.before[0].identifier,
        after: qst.after[0].identifier,
        question: {
          href: '/content/' + qst.key // TODO get correct question uuid
        }
      });
    });

    return sriClient.put('/surveys/answers/' + body.key, body).then(function () {
      let status = {
        'link-guid': participantCode,
        submitted: true
      };
      return sriClient.put('/surveys/' + surveyKey + '/status', status);
    });
  };

  /**
 * Get all the answers for the given survey and participant code if present.
 * surveyKey param can be omitted and will use the current selected survey.
 */
  service.getAnswers = function (surveyKey, participantCode, useCache = true, expand) {
    const d = $q.defer();

    if (useCache && service.data.answers) {
      d.resolve(service.data.answers);
      return d.promise;
    }

    let codeStr = '';
    if (participantCode) {
      codeStr = '&code=' + participantCode;
    }

    let expandStr = '';
    if (expand) {
      expandStr = '&expand=' + expand;
    }

    surveyKey = surveyKey || service.data.survey.key;

    if (surveyKey) {
      const url = '/surveys/answers?survey=/surveys/' + surveyKey + codeStr + expandStr;
      sriClient.getAll(url).then(answers => {
        if (useCache) {
          service.data.answers = answers;
        }
        d.resolve(answers);
      });
    } else {
      d.reject('Survey key needed');
    }

    return d.promise;
  };

  service.getAllSurveysAndAnswers = async () => {
    const surveysResp = await sriClient.getAll('/surveys', { limit: 200 });
    const surveysAnswersResp = await sriClient.getAll('/surveys/answers', { limit: 500 });
    return { surveys: surveysResp, answers: surveysAnswersResp };
  };

  /**
 * Get the participant status in the given survey.
 */
  service.getParticipantSurveyStatus = function (surveyKey, participantCode) {
    const d = $q.defer();

    if (surveyKey) {
      const url = '/surveys/' + surveyKey + '/status?link-guid=' + participantCode;
      sriClient.get(url).then(status => {
        d.resolve(status);
      }, err => {
        d.reject(err);
      });
    } else {
      d.reject({ status: 500, body: { message: 'Survey key needed' } });
    }

    return d.promise;
  };

  service.clear = function () {
    delete service.data.school;
    delete service.data.survey;
    delete service.data.location;
  };

  service.validEmail = function (email) {
    // const d = $q.defer();

    // const url = '/mailer/emailaddress/validate';
    // sriClient.post(url, {emailAddresses: [email]}, {baseUrl: 'https://testapi.katholiekonderwijs.vlaanderen'}).then(status => {
    //   d.resolve(status);
    // }, err => {
    //   d.reject(err);
    // });

    // return d.promise;

    if (!email) {
      return false;
    }

    let emailregex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    return emailregex.test(email.toLowerCase());
  };

  return service;

  /**
 * Some extra data could be added to the survey object for the screen visualization
 * and should be removed before send it to the api
 */
  function clearExtraData(survey) {
    if (survey.groups) {
      survey.groups.forEach(group => {
        delete group.lines;

        if (group.participants) {
          group.participants.forEach(participant => {
            delete participant.selected;
            delete participant.canBeRemoved;
            delete participant.img;
            delete participant.isNew;
          });
        }
      });
    }

    // TODO in the future all env will have the /sam prefix in smanesacholing api and we can ride of this
    if (survey.location) {
      survey.location.href = survey.location.href.replace('/sam/', '/');
    }
    if (survey.school) {
      survey.school.href = survey.school.href.replace('/sam/', '/');
    }
  }
}];
