/* eslint-disable max-len */
module.exports = ['sriClient', 'Utils', 'dateUtils',
  function (sriClient, Utils, dateUtils) { //eslint-disable-line

    const dataProcessingHelpers = {
      schools: {
        /**
         * This method extract the data we need in the way we need it for
         * a given SAM API element
         * @param {*} SAMElement
         * @param {*} level
         * @returns
         */
        extractDataFromSAMEl: (SAMElement, level) => {
          return {
            ...SAMElement,
            name: SAMElement.$$displayName,
            schoolsHrefsForGettingDirectors: [SAMElement.$$meta.permalink],
            level
          };
        }
      },
      governingInstitutions: {
        /**
         * It returns the intersection between the two arrays of VOS hrefs
         * @param {*} VOSSchools
         * @param {*} govInst
         * @returns
         */
        getSchoolsHrefsIntersection: (VOSSchools, govInst) => {
          // In the Governing Institutions the users are only able to get the directors from the schools
          // they are working on.
          // That means that we can only return the school hrefs that are the intersection
          // between the `VOSSchools` hrefs and the Governing Institution VOSSchools hrefs

          // Depending on the type of school they could have the schools in one
          // property or the other. Goverinng instituttions can have boardings and schools at the same time
          const govInstSchools = [...govInst.$$schools, ...govInst.$$cvos, ...govInst.$$boardings];
          if (govInstSchools.length) {
            const schoolsHrefs = VOSSchools.map(x => x.$$meta.permalink);
            const govInstSchoolsHrefs = govInstSchools.map(x => x.href);
            return govInstSchoolsHrefs.filter(x => schoolsHrefs.includes(x));
          }
          return [];
        },
        /**
         * This method returns the list of Governing Instituion hrefs from a given
         * Official Schools array
         * @param {*} officialSchools
         * @param {*} includeEnded
         * @returns
         */
        getHrefsFromOfficialSchools: (officialSchools, includeEnded) => {
          return [
            ...new Set(
              officialSchools.map(officialSchool => {
                if (!includeEnded) {
                  const activeGovInts = dateUtils.getActiveResources(officialSchool.$$governingInstitutions);
                  return activeGovInts.length > 0 && activeGovInts[0].href;
                }
                // legacy-code: this also include the "non-active" Governing Institutions
                return officialSchool.$$governingInstitutions.map(govInst => govInst.href);
              })
                .filter(x => x)
                .flat()
            )
          ];
        }
      }
    };

    const getSAMGovInstFromVOSSchools = async (VOSSchools, level, includeEnded = false) => {
      // Get hrefs from the old-api
      const officialSchoolGovInstsHrefs = dataProcessingHelpers.governingInstitutions.getHrefsFromOfficialSchools(VOSSchools, includeEnded);

      // Get data from the Governing Institutions from the old-api, in order to get the
      // institutions number from them to be able to find the SAM hrefs for those
      const officialSchoolGovInsts = officialSchoolGovInstsHrefs.length > 0 ? await sriClient.getAllHrefs(officialSchoolGovInstsHrefs) : [];

      const SAMOfficialSchoolsGovInstsResps = await Promise.all(
        officialSchoolGovInsts.filter(gi => gi.institutionNumber).map(async (govInst) => {
          const govInstSAMHref = await apiHelpers.SAMAPI.getHrefFromInstNum(govInst.institutionNumber);
          const [govInstResp] = await apiHelpers.SAMAPI.getElementsFromSam([govInstSAMHref], level);

          return {
            ...govInstResp,
            schoolsHrefsForGettingDirectors: dataProcessingHelpers.governingInstitutions.getSchoolsHrefsIntersection(VOSSchools, govInst)
          };
        })
      );

      const SAMOfficialSchoolsGovInsts = SAMOfficialSchoolsGovInstsResps.flat();

      // this is a pure-js solution for applying a lodash uniqueBy
      return Utils.uniqueBy(SAMOfficialSchoolsGovInsts, 'key');
    };

    const getVOSOffSchoolFromSAMSchoolEntHref = async (SAMSchoolEntityHref) => {
      // We get the related Official Schools for a the School Entity
      const [relatedOfficialSchoolsHref] = await apiHelpers.SAMAPI.getRelatedOfficialSchoolsHrefs(SAMSchoolEntityHref);
      // Then we get the data about that Official School from VOS
      const officialSchool = await apiHelpers.VOSAPI.getOfficialSchool(relatedOfficialSchoolsHref);
      return officialSchool;
    };

    const apiHelpers = {
      SAMAPI: {
        getSAMExtIdentifierFromHref: async (SAMHref) => {
          const extIdentifiersResp = await sriClient.getAll('/sam/organisationalunits/externalidentifiers', {
            organisationalUnit: SAMHref
          });

          const [extIdentifierResp] = extIdentifiersResp;
          return extIdentifierResp;
        },
        /**
         * Get the SAM href for a given institution number
         * @param {*} institutionNumber
         * @returns
         */
        getHrefFromInstNum: async (institutionNumber) => {
          const extIdentifiersResp = await sriClient.getAll('/sam/organisationalunits/externalidentifiers', {
            value: institutionNumber,
            type: 'INSTITUTION_NUMBER'
          });

          const [samSchoolHref] = extIdentifiersResp.map(s => s.organisationalUnit.href);
          return samSchoolHref;
        },
        /**
         * Get the schools data for a given SAM hrefs and return a unique array
         * of the items processed in the way we need
         * @param {*} SAMHrefs
         * @param {*} level
         * @returns
         */
        getElementsFromSam: async (SAMHrefs, level) => {
          const SAMResp = await sriClient.getAll('/sam/organisationalunits', {
            hrefs: SAMHrefs.join(',')
          });

          return [...new Set(SAMResp)].map(SAMEl => {
            return dataProcessingHelpers.schools.extractDataFromSAMEl(SAMEl, level);
          });
        },
        /**
         * Get the Governing Institutions for a given SAM Hrefs and return a processed
         * array with the data we need.
         * @param {*} SAMHrefs
         * @param {*} level
         * @returns
         */
        getGovInstsForGivenSchoolsSAMHrefs: async (SAMHrefs, level) => {
          const govInstRels = await sriClient.getAll('/sam/organisationalunits/relations', {
            type: 'GOVERNS',
            status: 'ACTIVE',
            to: SAMHrefs.join(','),
            expand: 'results.from'
          });

          return govInstRels.reduce((prevRelations, currRelation) => {
            const govInstData = currRelation.from.$$expanded;
            // the information of the SAM school is hold on the `to` property of the relation object
            const SAMSchoolHref = currRelation.to.href;

            const duplicatedEntry = prevRelations.find(x => x.key === govInstData.key);
            if (duplicatedEntry) {
              const schoolsHrefsForGettingDirectors = duplicatedEntry.schoolsHrefsForGettingDirectors;
              duplicatedEntry.schoolsHrefsForGettingDirectors = [...new Set([...schoolsHrefsForGettingDirectors, SAMSchoolHref])];
              return prevRelations;
            }
            const govInstDataProcessed = dataProcessingHelpers.schools.extractDataFromSAMEl(govInstData, level);
            prevRelations.push({
              ...govInstDataProcessed,
              schoolsHrefsForGettingDirectors: [SAMSchoolHref]
            });

            return prevRelations;
          }, []);
        },
        /**
         * This method get the schools for a specific Governing Institution
         * using his href
         * @param {*} governingInstitutionHref
         * @returns
         */
        getGovInstSchoolsHrefs: async (governingInstitutionHref) => {
          const resp = await sriClient.getAll('/sam/organisationalunits/relations', {
            type: 'GOVERNS',
            status: 'ACTIVE',
            from: governingInstitutionHref
          });

          return resp && resp.map(x => x.to.href);
        },
        /**
         * Get the unique related School Entities for a given SAM Officil School href
         * @param {*} SAMOfficialSchoolHref
         * @returns
         */
        getRelatedSchoolEntities: async (SAMOfficialSchoolHref, level) => {
          const SAMResp = await sriClient.getAll('/sam/organisationalunits/relations', {
            type: 'CAN_ACT_AS',
            status: 'ACTIVE',
            to: SAMOfficialSchoolHref,
            expand: 'results.from'
          });
          const relatedSchoolsData = SAMResp.map(x => x.from.$$expanded);
          return relatedSchoolsData.map(SAMEl => dataProcessingHelpers.schools.extractDataFromSAMEl(SAMEl, level));
        },
        /**
         * Get the unique related Official Schools for a given SAM School Entity href
         * @param {*} SAMSchoolEntityHref
         * @returns
         */
        getRelatedOfficialSchoolsHrefs: async (SAMSchoolEntityHref) => {
          const SAMResp = await sriClient.getAll('/sam/organisationalunits/relations', {
            type: 'CAN_ACT_AS',
            status: 'ACTIVE',
            from: SAMSchoolEntityHref
          });

          return SAMResp.map(x => x.to.href);
        }
      },
      VOSAPI: {
        getVOSOffSchoolFromSAMSchoolEntHref,
        getSAMGovInstFromVOSSchools,
        /**
         * Get the official School data from VOS for a given SAM School href
         * @param {*} SAMHref
         * @returns
         */
        getOfficialSchool: async (SAMHref) => {
          if (!SAMHref) return undefined;

          const externalIdentifier = await apiHelpers.SAMAPI.getSAMExtIdentifierFromHref(SAMHref);

          const officialSchool = externalIdentifier && await sriClient.get(`/schools/${externalIdentifier.value}`);
          return officialSchool;
        }
      }
    };

    return {
      apiHelpers,
      dataProcessingHelpers
    };
  }];
