var services = angular.module('services', []) //eslint-disable-line
  .factory('BackendManager', require('./backend'))
  .factory('Survey', require('./survey'))
  .factory('Reports', require('./reports'))
  .factory('XlsxReports', require('./xlsxReports'))
  .factory('Organisations', require('./organisations'))
  .factory('dataHelpers', require('./dataHelpers'))
  .factory('Utils', require('./utils'));

module.exports = services;
