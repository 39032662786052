module.exports = [ '$window', '$q', function ($window, $q) { //eslint-disable-line
  const that = {};

  /** Wellbe utils */

  that.groupContainsEmail = (group, email) => {
    let found = false;

    if (!email) {
      return false;
    }

    group.participants.forEach(participant => {
      if (participant.email.toUpperCase() === email.toUpperCase() || participant.name === email) { // p.name==email is only done to support old surveys
        found = true;
      }
    });

    return found;
  };

  that.surveyContainsEmail = (survey, email) => {
    let found = false;

    if (!email) {
      return false;
    }

    survey.groups.forEach(group => {
      group.participants.forEach(participant => {
        if (participant.email.toUpperCase() === email.toUpperCase()
          || participant.name === email) { // p.name==email is only done to support old surveys
          found = true;
        }
      });
    });

    return found;
  };

  /**
     * Sort the given questions, best sum of possitive percentages on top
     *
     * @param {*} questions
     */
  that.sortQuestionsPositiveTop = (questions) => {
    return questions.sort((q1, q2) => {
      const possitiveQ1 = q1.sections[3].percentage + q1.sections[4].percentage;
      const possitiveQ2 = q2.sections[3].percentage + q2.sections[4].percentage;

      if (possitiveQ1 !== possitiveQ2) {
        return possitiveQ2 - possitiveQ1;
      }

      return q2.sections[4].percentage - q1.sections[4].percentage;
    });
  };

  /**
     * Sort the given questions, best sum of negative percentages on top
     *
     * @param {*} questions
     */
  that.sortQuestionsNegativeTop = (questions) => {
    return questions.sort((q1, q2) => {
      const sumNegativePercentageQ1 = q1.sections[0].percentage + q1.sections[1].percentage;
      const sumNegativePercentageQ2 = q2.sections[0].percentage + q2.sections[1].percentage;

      if (sumNegativePercentageQ1 !== sumNegativePercentageQ2) {
        return sumNegativePercentageQ2 - sumNegativePercentageQ1;
      } if (q2.sections[0].percentage !== q1.sections[0].percentage) {
        return q2.sections[0].percentage - q1.sections[0].percentage;
      }

      return q2.sections[4].percentage - q1.sections[4].percentage;
    });
  };

  /**
     * Sort the given questions by title (description)
     *
     * @param {*} questions
     */
  that.sortQuestionsByTitle = (questions) => {
    return [...questions].sort((a, b) => (a.description > b.description ? 1 : -1));
  };

  /**
   * Get answers only of the selected group (if any otherwise all the answers)
   */
  that.filterAnswersByGroup = (answers, groupKey) => {
    return answers.filter(a => {
      if (groupKey && groupKey !== 'ALL') {
        return a.group === groupKey;
      }
      return true;
    });
  };

  that.filterAnswersByGroupType = (answers, groupType) => {
    return answers.filter(a => {
      if (a.$$groupType) {
        return !groupType || a.$$groupType === groupType;
      }
      return false;
    });
  };

  /**
  * Get the amount of particpant that already submitted answers
  */
  that.countParticipantsAnswered = (survey) => {
    let count = 0;

    if (survey.groups) {
      survey.groups.forEach(group => {
        if (group.participants && group.participants.length > 0) {
          group.participants.forEach(participant => {
            if (participant.submitted) {
              count++;
            }
          });
        }
      });
    }

    return count;
  };

  that.splitArray = (a, cols) => {
    const out = []; let
      chunk = [];

    // sort array vertically according to given columns
    a = sortVertically(a, cols);

    a.forEach(element => {
      chunk.push(element);
      if (chunk.length === cols) {
        out.push(chunk.slice());
        chunk = [];
      }
    });

    if (chunk.length > 0) {
      out.push(chunk.slice());
    }

    return out;
  };

  function sortVertically(data, cols) {
    const out = [];
    const rows = Math.ceil(data.length / cols);
    let col; let
      row;
    for (row = 0; row < rows; ++row) {
      for (col = 0; col < cols; ++col) {
        const idx = row + (col * rows);
        if (data[idx]) {
          out.push(data[idx]);
        }
      }
    }
    return out;
  }

  /** */


  const appendZero = (number) => {
    return number > 9 ? number : 0 + '' + number;
  };
  that.appendZero = appendZero;

  function excludeFromStart(str, tokens) {
    tokens.map((token) => {
      if (str.startsWith(token)) {
        str = str.slice(token.length);
        str = excludeFromStart(str, tokens);
      }
    });
    return str;
  }
  function excludeFromEnd(str, tokens) {
    tokens.map((token) => {
      if (str.endsWith(token)) {
        str = str.slice(0, -token.length);
        str = excludeFromEnd(str, tokens);
      }
    });
    return str;
  }

  that.toString = (date) => {
    return appendZero(date.getDate()) + '/' + appendZero(date.getMonth() + 1) + '/' + date.getFullYear();
  };

  that.dateToString = (date) => {
    return date.getFullYear() + '-' + appendZero(date.getMonth()+1) + '-' + appendZero(date.getDate()); //eslint-disable-line
  };

  that.timeToString = (date) => {
    return date.getFullYear() + '-' + appendZero(date.getMonth()+1) + '-' + appendZero(date.getDate()) + ' ' + //eslint-disable-line
     date.getHours() + ':' + date.getMinutes();
  };

  that.inputToDateString = (input) => {
    let date;
    if (typeof input === 'string') {
      date = input.split('/').reverse().join('-');
    } else {
      date = that.dateToString(input);
    }
    return date;
  };

  that.printDate = (dateString) => {
    return dateString.split('-').reverse().join('/');
  };

  that.getUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8); //eslint-disable-line
      return v.toString(16);
    });
  };

  that.charactersCount = (text) => {
    return text ? text
      .replace('&nbsp;', ' ')
      .replace(/(<([^>]+)>)/ig, '').length : 0;
  };

  that.replaceSpecialCharacters = (string) => {
    return string
      .replace(/[\u00C0-\u00C5\u00E0-\u00E5]/g, 'a')
      .replace(/[\u00C6\u00E6]/g, 'ae')
      .replace(/[\u00C7\u00E7]/g, 'c')
      .replace(/[\u00C8-\u00CB\u00E8-\u00EB]/g, 'e')
      .replace(/[\u00CC-\u00CF\u00EC-\u00EF]/g, 'i')
      .replace(/[\u00D1\u00F1]/g, 'n')
      .replace(/[\u00D2-\u00D6\u00D8\u00F2-\u00F6\u00F8]/g, 'o')
      .replace(/[\u00D9-\u00DC\u00F9-\u00FC]/g, 'u')
      .replace(/[\u00DD\u00FD\u00FF]/g, 'y')
      .replace(/[\u00DF]/g, 'ss')
      .replace(/[ł]/g, 'l')
      .replace(/[^a-zA-Z0-9\-]/g, '');
  };

  that.sanitizeHtml = (string, options) => {
    const sanitize = require('sanitize-html');
    if (options) {
      string = sanitize(string, options);
    }
    string = excludeFromStart(string, ['<br />', '<br>']);
    string = excludeFromEnd(string, ['<br />', '<br>']);
    string = string.replace(/<br\s*[\/]?>/gi, '<br>'); // replcae <br /> with <br>
    return string;
  };

  that.containsDateOfBirth = (number, dateOfBirth) => {
    if (!number || !dateOfBirth) {
      return false;
    }
    const reverse = dateOfBirth.split('-');
    reverse[0] = reverse[0].substring(2);
    const value = number.replace(/\s/g, '');
    return value.match('^[0-9]*' + reverse.join('') + '[0-9]*$');
  };


  that.compact = (title) => {
    if (!title) {
      return '';
    }
    return title.length < 130 ? title : title.substring(0, 130) + '...';
  };

  that.isPositiveIntegerNumber = (age) => {
    if (typeof age === 'string' || age instanceof String) {
      age = age.trim();

      if (age == '') {
        return false;
      }

      age = Number(age);
    }

    return Number.isInteger(age) && age >= 0;
  };

  that.addCSSClassToHTMLTables = (html) => {
    return html.split('<table>').join('<table class="table table-bordered">');
  };

  that.nameWithOutExtension = (name) => {
    const n = name.lastIndexOf('.');
    return n > -1 ? name.substr(0, n) : name;
  };

  /** School api resource utils */

  that.getSchoolName = (school) => {
    return school.$$name;
  };

  that.getSchoolAddress = (school) => {
    if (!school.seatAddresses) {
      return '';
    }
    const address = school.seatAddresses[0].address;
    return address.street + ' ' + address.houseNumber + ', ' + address.zipCode + ' ' + address.city;
  };

  that.getOrganisationalUnitAddress = (contactdetails) => {
    if (!contactdetails || !contactdetails.value) {
      return '';
    }
    const address = contactdetails.value;
    return address.street + ' ' + address.houseNumber + ', ' + address.zipCode + ' ' + address.city;
  };

  that.getLocationAddress = (physicalLocation) => {
    const address = physicalLocation.address;
    return address.street + ' ' + address.houseNumber + ', ' + address.zipCode + ' ' + address.city;
  };

  that.getLocationAddresses = (survey, school) => {
    return survey.location ? survey.location.display : school.locations.map(l => l.display).join(' - ');
  };

  /**
   * This method is an pure-js equivalent to the lodash uniqueBy
   * @param {*} obj
   * @param {*} key
   */
  that.uniqueBy = (objArr, key) => {
    if (!key) {
      throw new Error('uniqueBy needs a key');
    }
    return objArr.filter((value, index, self) => self.map(x => x[key]).indexOf(value[key]) === index);
  };

  return that;
}];
