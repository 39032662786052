module.exports = ['$q', '$rootScope', '$location', 'constants', 'settings', 'questions', 'sriClient', 'Utils',
function ($q, $rootScope, $location, constants, settings, questions, sriClient, Utils) { //eslint-disable-line

    'use strict';

    const html2pdf = require('../module/html2pdf/html2pdf.bundle');

    var service = {};

    /**
     * It returns the survey section from the list of survey
     * sections. In case it is still not there it creates it
     * and add it to the list
     *
     * @param {object} surveyQuestion
     * @param {array<object>} surveySectionList
     * @returns
     */
    function getSurveySection(surveyQuestion, surveySectionList) {
      const surveySectionTitle = surveyQuestion.component;
      let surveySection = surveySectionList.find(c => c.title === surveySectionTitle);

      if (surveySection) { return surveySection; }

      // if the surveySection does not exist yet on the array we create one
      surveySection = {
        title: surveySectionTitle,
        questions: []
      };

      surveySectionList.push(surveySection);
      return surveySection;
    }

    /**
  * Create a tree with all the different statement components and the related questions inside them.
  * Taking in account group filter so only questions of the current selected will be included.
  */
    service.generateComponentsStructure = (groupType, surveyKey) => {
      const surveySectionsList = [];

      [...questions.survey].forEach(qst => {
        // clear possible already calculated percentage values
        qst = Object.assign({}, qst, {
          sections: [],
          compareSections: [],
          surveyKey
        });

        if ((!groupType || qst.target.indexOf(groupType) !== -1)) {
          const surveySection = getSurveySection(qst, surveySectionsList);
          surveySection.questions.push(qst);
        }
      });

      return surveySectionsList.sort((a, b) => (a.title > b.title ? 1 : -1));
    };

    /**
   * config: {
   *  situation: before|after
   *  compareSections: true|false
   *  skipInfo: true|false (default: false)
   *  debug: true|false
   * }
   */
    service.generatePercentageBoxes = (allAnswers, components, overall, config) => {
      let sectionsType = config && config.compareSections ? 'compareSections' : 'sections';

      components.forEach(component => {
        component[sectionsType] = [];

        component.questions.forEach(qst => {
        // fill the question level percentages
          let qstAnswers = [];
          allAnswers.forEach(participant => {
            let filtered = participant.answers.filter(a => a.question.href.indexOf(qst.key) !== -1);
            if (filtered.length > 0) {
              qstAnswers.push(filtered[0]);
            }
          });

          fillPercentages(qst, qstAnswers, config.situation, sectionsType, config);
        });

        // fill the component level percentages (using already filled questions percentages)

        // consider only questions with at least 1 answer given
        let totalComponentQuestions = component.questions.filter(qst => qst.answers.length > 0).length;
        let i = 0;

        constants.possibleAnswers.forEach(pa => { // pa = DO_NOT_AGREE_AT_ALL|DO_NOT_AGREE|DO_NOT_KNOW|AGREE|TOTALLY_AGREE
          let sumTypePercentage = 0;
          component.questions.forEach(qst => {
            sumTypePercentage += qst[sectionsType][i].percentage || 0;
          });
          component[sectionsType].push({ percentage: Math.floor(sumTypePercentage / totalComponentQuestions), color: constants.reportColors[i] });
          i++;
        });

        completePercentageBox100Percent(component, sectionsType);

        if (!(config && config.skipInfo)) {
          fillHoverPercentagesBoxTooltip(component, sectionsType);
        }
      });

      // fill the overall level percentages (using already filled components percentages)
      if (overall) {
        let i = 0;
        overall[sectionsType] = [];

        constants.possibleAnswers.forEach(pa => { // pa = DO_NOT_AGREE_AT_ALL|DO_NOT_AGREE|DO_NOT_KNOW|AGREE|TOTALLY_AGREE
          let totalComponents = components.length;
          let sumTypePercentage = 0;
          components.forEach(component => {
            sumTypePercentage += component[sectionsType][i].percentage || 0;
          });
          overall[sectionsType].push({ percentage: Math.floor(sumTypePercentage / totalComponents), color: constants.reportColors[i] });
          i++;
        });

        completePercentageBox100Percent(overall, sectionsType);

        if (!(config && config.skipInfo)) {
          fillHoverPercentagesBoxTooltip(overall, sectionsType);
        }
      }

      // TODO remove log
      if (config && config.debug) {
        components.forEach(c => console.log(c.title, c.questions, c[sectionsType]));
      }
    };

    service.downloadPDF = (config) => {
      let element = document.getElementById(config.elementId);

      let canvasOpt = {
        scale: 1, imageTimeout: 1000, useCORS: true, allowTaint: true, async: false
      };
      if (config.onclone) {
        canvasOpt.onclone = config.onclone;
      }

      let opt = {
      // margin:       [1.5, -3], // [v -1.85, h]
        margin: [1, -3, 0.2, -3], // [v -1.85, h]
        filename: (config.filename ? config.filename : 'report') + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: canvasOpt,
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
        useCORS: true,
        allowTaint: true
      };

      try {
        return html2pdf(element, opt);
      } catch (e) {
        console.log('ERROR creating the report pdf:', e);
      }
    };

    service.togglePrintElements = (show) => {
      document.getElementById('print-logo').style.display = show ? 'block' : 'none';
      document.getElementById('back-button').style.display = !show ? 'block' : 'none';
      console.log('Breaks: ', document.querySelectorAll('.paging'));
      document.querySelectorAll('.paging').forEach((e) => e.style.display = show ? 'block' : 'none');
    };

    return service;


    /**
 * Fill the question sections with the percentages according to the answers given by participants.
 * Take in count the 'impact' field of the question, if it's NEGATIVE then the percentages are computed to the opposite side.
 *
 * @param {*} question
 * @param {*} relatedAnswers answers related to the given question
 * @param {*} situation before|after
 */
    function fillPercentages(question, relatedAnswers, situation, sectionsType, config) {
      let totalAnswers = relatedAnswers.length;
      let percentages = [];
      let i = 0;

      question[sectionsType] = [];
      question.answers = relatedAnswers;

      constants.possibleAnswers.forEach(pa => { // pa = DO_NOT_AGREE_AT_ALL|DO_NOT_AGREE|DO_NOT_KNOW|AGREE|TOTALLY_AGREE
        let countedAnswers = relatedAnswers.filter(answers => {
          return answers[situation] === pa;
        }).length;
        question[sectionsType].push({
          percentage: Math.floor(countedAnswers * 100 / totalAnswers), color: constants.reportColors[i], totalAnswers, countedAnswers
        });
        i++;
      });

      // turn percentage if impact is negative (for current state) or always if is 'after' situation (#16090)
      if ((question.impact === 'NEGATIVE' || situation === 'after') && !config.excelReport) {
        let newSectionsOrder = [];
        i = 0;
        constants.possibleAnswers.forEach(pa => { // pa = DO_NOT_AGREE_AT_ALL|DO_NOT_AGREE|DO_NOT_KNOW|AGREE|TOTALLY_AGREE
          newSectionsOrder.push({
            percentage: question[sectionsType][question[sectionsType].length - 1 - i].percentage,
            color: constants.reportColors[i],
            countedAnswers: question[sectionsType][question[sectionsType].length - 1 - i].countedAnswers,
            totalAnswers: question[sectionsType][question[sectionsType].length - 1 - i].totalAnswers
          });
          i++;
        });
        question[sectionsType] = newSectionsOrder;
      }

      completePercentageBox100Percent(question, sectionsType);

      if (!(config && config.skipInfo)) {
        // fill question hover info box
        fillHoverPercentagesBoxTooltip(question, sectionsType);
      }
    }
  }];


/**
 * Create the tooltip message to be showed on hover the percentages box.
 * And assign it to the given item.
 *
 * @param {*} item question|component|overall
 */
function fillHoverPercentagesBoxTooltip(item, sectionsType) {
  let positiveImpact = !item.impact || item.impact === 'POSITIVE';

  item.info = '<span>helemaal ' + (positiveImpact ? 'niet' : '') + ' akkoord: ' + item[sectionsType][0].percentage + '%</span>'
              + '<span>' + (positiveImpact ? 'niet ' : '') + 'akkoord: ' + item[sectionsType][1].percentage + '%</span>'
              + '<span>' + (!positiveImpact ? 'niet ' : '') + 'akkoord: ' + item[sectionsType][3].percentage + '%</span>'
              + '<span>helemaal ' + (!positiveImpact ? 'niet' : '') + ' akkoord: ' + item[sectionsType][4].percentage + '%</span>'
              + '<span>ik weet het niet/niet van toepassing: ' + item[sectionsType][2].percentage + '%</span>';
  return item.info;
}

/**
 * Because of rounds the item percetge box could get with a sum less than 100%.
 * To fix that we add to the last section of the box (with a value > 0) the neccesary % to fill a total of 100%.
 *
 * @param {*} item question|component|overall
 */
function completePercentageBox100Percent(item, sectionsType) {
  let boxTotal = 0;
  item[sectionsType].forEach(s => boxTotal += s.percentage);

  let diff = 100 - boxTotal;
  if (diff > 0) {
    let i = 4;
    for (i = 4; i > -1; i--) {
      if (item[sectionsType][i].percentage > 0) {
        item[sectionsType][i].percentage += diff;
        break;
      }
    }
  }
}
