

module.exports = ['$rootScope', 'events', function($rootScope, events) {

	return {
        restrict: 'A',

        link: function(scope, elem, attrs) {

          elem[0].addEventListener(events.UPDATE_MODEL, updateDebug);

          function updateDebug(e) {
            // console.log('DEBUG - Event submitted responses', e.detail);
						// console.log(elem);
						var html = '<p>';
						e.detail.map(function(d) {
							html += JSON.stringify(d) + ' <p> ';
						});

						$('#'+attrs.qtiDebug).html(html);
          }
        }
    };
}];
