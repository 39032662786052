

module.exports = ['$rootScope', '$q', 'qtiCommunication', 'events', function($rootScope, $q, qtiCommunication, events) {

	return {
		restrict: 'A',
		scope: { responses: '=qtiModel' },

		link: function(scope, elem, attrs) {

			console.log('QTI model directive load');

			elem[0].addEventListener(Events.UPDATE_MODEL, updateModel);

			scope.gapCount = 0;
			scope.choiceCount = 0;


      function submitted(e) {
        console.log('Event submitted responses', e.detail);

        var responses = e.detail.filter(function(r) {
          return r.candidateResponse;
        }).map(function(r) {
          var resp = {identifier: r.identifier};

          if (r.candidateResponse instanceof Array) {
            resp.values = r.candidateResponse;
          } else {
            resp.value = r.candidateResponse;
          }
					return resp;
				});

        qtiCommunication.saveQuestion($rootScope.currentQst, responses, $rootScope.loadedAnswer);
      }

			function updateModel(e) {
				var responses = [];
				e.detail.map(function(d) {
					if (d.identifier) {
						responses.push({identifier: d.identifier, value: d.candidateResponse});
					}
				});

				setTimeout(function () {
					scope.$apply(function () {
						scope.responses = responses;
						console.log('Updated model to', scope.responses);
        	});
    		}, 100);
			}

			// $scope.$watch('responses', function(n, o) {
	    //   console.log('Watch responses:', n, o);
	    // });



			function registerChoice(e) {
				console.log('QTI recived choice', e.detail);

				var identifier = e.detail.identifier;

					if (scope.responses && scope.responses.length > 0) {
						var defaultResp = scope.responses.filter(function(d) {
							var value = d.value ? d.value : d.values;
							return value.indexOf(identifier) !== -1;
						});

						if (defaultResp.length > 0) {
							console.log('Click checkbox', identifier);
							e.detail.defaultChecked();
						}
          }
			}

			function registerTextEntry(e) {
				console.log('QTI received text entry', e.detail);

				var identifier = e.detail.responseIdentifier;

				getDefaultForIdentifier(identifier).then(function(value) {
          if (value) {
            e.detail.default(value);
          }
        });
			}

			function registerOrderList(e) {
				console.log('QTI received order list', e.detail);

				var identifier = e.detail.responseIdentifier;

				getDefaultForIdentifier(identifier).then(function(value) {
					if (value) {
						e.detail.defaultOrder(value);
					} else {
						e.detail.loadLayout();
					}
				});
			}

			function registerMatchList(e) {
				console.log('QTI received match list', e.detail);

				var identifier = e.detail.responseIdentifier;

				getDefaultForIdentifier(identifier).then(function(value) {
          if (value) {
            e.detail.defaultMatch(value);
          }
        });
			}

			function registerDropDownChoice(e) {
				console.log('QTI received drop down choice', e.detail);

				var identifier = e.detail.responseIdentifier;

				getDefaultForIdentifier(identifier).then(function(value) {
          if (value) {
            e.detail.defaultChoice(value);
          }
        });
			}


			function getDefaultForIdentifier(identifier) {

        var d = $q.defer();

        	if (scope.responses && scope.responses.length > 0) {
            var defaultResp = scope.responses.filter(function(d) { return d.identifier === identifier; } );

            if (defaultResp.length > 0) {
              d.resolve(defaultResp[0].value);
            } else {
							d.resolve();
						}
          } else {
						d.resolve();
					}

					return d.promise;
			}

			scope.$on('$destroy', function () {
			  console.log('captured $destroy event');
				this.removeEventListener(Events.PROCESSED_RESPONSES, submitted);
				this.removeEventListener(Events.UPDATE_RESPONSES, updateModel);

				this.removeEventListener(Events.LOADED_CHOICE, registerChoice);
				this.removeEventListener(Events.LOADED_TEXTENTRY, registerTextEntry);
				this.removeEventListener(Events.LOADED_ORDERLIST, registerOrderList);
				this.removeEventListener(Events.LOADED_MATCHLIST, registerMatchList);
				this.removeEventListener(Events.LOADED_DROPDOWNCHOICE, registerDropDownChoice);
			});
		}
	};
}];
