
module.exports = ['$rootScope', '$http', '$q', 'settings', function($rootScope, $http, $q, settings) {

  let xslt, xml;

  this.loadQti = function(templatePath) {
    if (!templatePath) {
      return;
    }

    let xmlstr;
    // var xml = require('../xml/S002-simple.html');
    // xml = require('../xml/'+ templatePath);    

    //let xml = '<?xml version="1.0" encoding="UTF-8"?><assessmentItem identifier="" adaptive="false" title="" timeDependent="false" xmlns="http://www.imsglobal.org/xsd/imsqti_v2p1">	<itemBody>		<choiceInteraction minChoices="1" maxChoices="1" shuffle="false">			<simpleChoice identifier="1_A" value="Ja">			</simpleChoice>			<simpleChoice identifier="1_B" value="Nee">			</simpleChoice>	</choiceInteraction>	</itemBody></assessmentItem>';
    if (templatePath === 'S001-simple.xml') {
      xmlstr = '<?xml version="1.0" encoding="UTF-8"?><assessmentItem identifier="" adaptive="false" title="" timeDependent="false" xmlns="http://www.imsglobal.org/xsd/imsqti_v2p1"><itemBody><choiceInteraction minChoices="1" maxChoices="1" shuffle="false"><simpleChoice identifier="YES" value="Ja"></simpleChoice><simpleChoice identifier="NO" value="Nee"></simpleChoice></choiceInteraction></itemBody></assessmentItem>';
      // xmlstr = '<?xml version="1.0" encoding="UTF-8"?><qti-assessmentitem identifier="" adaptive="false" title="" timeDependent="false" xmlns="http://www.imsglobal.org/xsd/imsqti_v2p1"><qti-itembody><qti-choiceinteraction minChoices="1" maxChoices="1" shuffle="false"><qti-simplechoice identifier="YES" value="Ja"></qti-simplechoice><qti-simplechoice identifier="NO" value="Nee"></qti-simplechoice></qti-choiceinteraction></qti-itembody></qti-assessmentitem>';
    } else if (templatePath === 'S002-simple.xml') {
      xmlstr = '<?xml version="1.0" encoding="UTF-8"?><assessmentItem identifier="" adaptive="false" title="" timeDependent="false" xmlns="http://www.imsglobal.org/xsd/imsqti_v2p1"><itemBody><choiceInteraction minChoices="1" maxChoices="1" shuffle="false"><simpleChoice identifier="DO_NOT_AGREE_AT_ALL" title="helemaal niet"></simpleChoice><simpleChoice identifier="DO_NOT_AGREE" title="eerder niet"></simpleChoice><simpleChoice identifier="AGREE" title="eerder wel"></simpleChoice><simpleChoice identifier="TOTALLY_AGREE" title="in sterke mate"></simpleChoice><simpleChoice identifier="DO_NOT_KNOW" title="ik weet het niet/niet van toepassing"></simpleChoice></choiceInteraction></itemBody></assessmentItem>';
    }
    
    // console.log(xml);

    // TODO This works to parse an xml string into xml document node
    //xml = ( new window.DOMParser() ).parseFromString(xmlstr, "text/xml")

    // const url = 'js/module/kathondvla-qti/xml/' + templatePath;    
    // let xml2 = load(url);

    // console.log('XML:',xml);
    
    var result = this.transform(xmlstr, templatePath);
    return result;
  }

  function load(file) {
    var request = new XMLHttpRequest();

    request.overrideMimeType("text/xml");

    request.open("GET", file, false);
    request.send();

    return request.responseXML;
  }


  function loadXMLDoc(url) {
    if (typeof XMLHttpRequest !== 'undefined')
    {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, false);
      // request MSXML responseXML for IE
      try { xhr.responseType = 'msxml-document'; } catch(e){}
      xhr.send();
      return xhr.responseXML;
    }
    else {
      try {
        var xhr = new ActiveXObject('Msxml2.XMLHTTP.3.0');
        xhr.open('GET', url, false);
        xhr.send();
        return xhr.responseXML;
      }
      catch (e) {
        // handle case that neither XMLHttpRequest nor MSXML is supported
      }
    }
  }

  this.transform = function(xmlstr, templatePath) {

    var result;
      
    if (window.ActiveXObject || "ActiveXObject" in window) {
      // IE11
      xml = xml || loadXMLDoc('/js/module/kathondvla-qti/xml/' + templatePath);
      xslt = xslt || loadXMLDoc('/js/module/kathondvla-qti/xslt/QTIToDirectives.xslt');
      
      result = xml.transformNode(xslt);
    } else {
      let parser = new DOMParser();
      xml = parser.parseFromString(xmlstr,"text/xml");
      xslt = xslt || load('/js/module/kathondvla-qti/xslt/QTIToDirectives.xslt');
      // console.log('Transform with xslt:', xslt);

      var processor = new XSLTProcessor();
      processor.importStylesheet(xslt);
      result = processor.transformToFragment(xml, document);
    }

    // dont use?
    // var result = processor.transformToDocument(xml);

    return result;
  }

  return this;
}];
