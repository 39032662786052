import oauthSettings from "./vsko-oauth-configuration-stripped.json";

export const settings = {
  //eslint-disable-line
  angularModuleName: "WellBe Project",
  projectName: "wellbe",
  projectTitle: "WellBe",
  projectVersion: "main",
  projectDescription: "A survey editor",
  googleAnalytics: {
    enabled: false,
    token: "", // GET YOUR OWN!!!.
  },
  apisAndUrls: {
    api: "https://api.katholiekonderwijs.vlaanderen",
    myVsko: "https://mijn.vsko.be/#/me",
    samApi: "https://api.katholiekonderwijs.vlaanderen/sam",
  },
  samenscholing: {
    name: "SAM-ACCESSTOKEN",
    token: "76fadd5f-2a07-11e5-be0a-00ffa0598608",
  },
  logging: {
    logentriesEnabled: false,
    level: "INFO",
    logentriesToken: "", // GET YOUR OWN TOKEN.
    sentry: {
      enabled: true,
      url: "https://945630e2fbbc4b2a96395dffd786c8cb@o268751.ingest.sentry.io/4504316438839296",
      sentryPerformanceSampleRate: 0.03,
      fetchInterceptor: false,
    },
  },
  oauth: oauthSettings,
  loginRequired: true,
  debug: false,
  sendRealEmails: "true",
  adminEmail: "greet.vanhove@katholiekonderwijs.vlaanderen",
  environment: "PROD",
};
