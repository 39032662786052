require('./table-row.scss');

class TableRow {

  constructor($scope){
    this.$scope = $scope;
  }

  // injection here
  static get $inject() {
    return [
      '$scope'
    ];
  }

  imageSrc() {
    return this.img ? (this.img) : '';
  }

  rowClick() {
    this.$scope.$emit('table_row_clicked', this.model);
  }

  remove() {
    this.$scope.$emit('table_row_removed', this.model);
  }

  $onInit() {
    return (() => {
    })();
  }

  $onChanges(changes) {
    if (changes.sRowModel && !changes.sRowModel.isFirstChange()) {
      console.log('Changes:', changes)
    }
  }
}


module.exports = {
  template: require('./table-row.html'),
  transclude: true,
  controllerAs: 'ctrl',
  controller: TableRow,
  bindings: {
    showRemove: '<?sShowRemove',
    img: '@?sImg',
    model: '<?sRowModel'
  }
};