module.exports = [ 'events',
  function (events) {
    return {
      restrict: 'E',
      template: require('../templates/choiceInteraction.html'),
      transclude: true,
      replace: true,      
      link: function(scope, element, attrs)
      {
        let responses = [];
        let maxChoices = +attrs.maxchoices;

        element[0].addEventListener(events.REGISTER_CHOICE, registerChoice);

        // listen for selection action from the inner choices and udpate response
        element[0].addEventListener(events.CHOICE_SELECTED, ev => {
          ev.stopPropagation();
          // console.log('Event catch in choiceinteraction: ', ev);

          // TODO handle response according to choice and interaction config (eg. if max choices is set )          

          choiceSelected(ev, responses, maxChoices);                    

          // emit event for parent assessmentitem UPDATE_RESPONSES
          const detail = responses.map(response => { return {identifier: response.identifier, candidateResponse: response.candidateResponse} });

          let event = new Event(events.UPDATE_RESPONSES, {bubbles: true, detail: detail});

          try {
            // NOT IE11 should set ev.details like this
            event.detail = detail;
          } catch(e) {
           // IE11 we dont care 
          }
          element[0].dispatchEvent(event);
        });
      }
    };
}];

let choiceElements = [];

/**
 * Choice selected/unselected, we should add a new response or remove an existing one considering: 
 * . checking if there is a max choices limitation
 */
function choiceSelected(event, responses, maxChoices) {
  
  if (event.detail.elementApi.isSelected && !event.detail.elementApi.isSelected()) {
    // unselected choice -> remove from responses 
    // TODO if maxchices isd fined we can't remove all, we need to seek and remove the unselected one
    responses.length = 0;
    return;
  } else if (maxChoices && maxChoices != 0 && responses.length === maxChoices) {
    // maxChoices limit reached, pop first response element and add the new one
    const popResponse = responses[0];
    popResponse.elementApi.setStatus(false);

    responses.splice(0, 1);
  } 
  
  responses.push({identifier: event.detail.identifier, candidateResponse: event.detail.value, elementApi: event.detail.elementApi});  
}

function registerChoice(event) {
  event.stopPropagation();
  choiceElements.push(event.detail.elementApi);
}