require('./css/stepHeader.scss');

class StepHeader {

    constructor(Survey, Utils, $sce){

      this.Survey = Survey;
      this.Utils = Utils;
      this.$sce = $sce;
    }
  
    // injection here
    static get $inject() {
      return [
        'Survey',
        'Utils',
        '$sce'
      ];
    }
    
    
    stepImageSrc() {
      return this.img ? (this.img) : '';
    }
    
    $onInit() {
      return (async () => {
        if (!this.school) {
          let school = this.Survey.getSchool();
          if (school) {
            this.school = { name: school.name, address: school.address };
          }
        }
        if (!this.location) {
          // show lcoation according what selected for the survey (could be just one location or all of the school)
          this.Survey.getSurvey().then(survey => {

            const school = this.Survey.getSchool();
            const location = this.Survey.getPhysicalLocation()
            if (survey || school) {
              this.location = { address: location && location.display ? location.display :
                survey && survey.location && survey.location.display ? survey.location.display : school.locations.map(l => l.display).join(' - ') };

              // console.log('Step-header location:',this.location)
            }
          })
        }
        
        if (this.messageOnRight) {
          this.messageOnRight = this.$sce.trustAsHtml(this.messageOnRight)
        }
      })();
    }
  
    $onChanges(changes) {
    }
  }
  
  
  module.exports = {
    template: require('./templates/stepHeader.html'),
    controllerAs: 'ctrl',
    controller: StepHeader,
    bindings: {
      stepNumber: '@',
      title: '@stepTitle',
      description: '@',
      img: '@?',
      school: '<?',
      location: '<?',
      messageOnRight: '<?'
    }
  };