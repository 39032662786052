module.exports = [ 'events',
  function (events) {
    return {
      restrict: 'E',
      template: require('../templates/assessmentItem.html'),
      transclude: true,
      replace: true,      
      link: function(scope, element, attrs)
      {
        // listen events from the inner interactions and update model when happens
        element[0].addEventListener(events.UPDATE_RESPONSES, ev => {
          ev.stopPropagation();

          // possible handle question responses but always emit to update model          
          let event = new Event(events.UPDATE_MODEL, {bubbles:true, detail: ev.detail});
          try {
            // NOT IE11 should set ev.details like this
            event.detail = ev.detail;
          } catch(e) {
           // IE11 we dont care 
          }
          element[0].dispatchEvent(event);
        });
      }
    };
}];