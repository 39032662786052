module.exports = { //eslint-disable-line
  survey:  [
  { key: '37f064cf-c484-46b6-a98e-ea4ae527251b',
  description: 'Ik slaag erin om de leerlingen te stimuleren om zelfstandig te leren.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'TEACHERS' ],
  screenOrder: 1,
  reportOrder: 2,
  tipsOrganisation: [
     'Nakijken of het aanbod van het vormingsbeleid voldoende is afgestemd op de noden van de medewerkers.'
   ],
  tipsIndividuals: 
   [ 'Interne of externe professionalisering aanbieden die aansluit bij de specifieke noden van leraren: vb. hoe omgaan met zelfstandigheidsdidactiek? Hoe leerlingen motiveren? Hoe aan de relatie leerling-leraar werken?' ] }
,
{ key: '5ad9193c-2a3d-421c-8360-78067d8e8923',
  description: 'Kennis en vaardigheden ontwikkelen bij leerlingen lukt me.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'TEACHERS' ],
  screenOrder: 6,
  reportOrder: 3,
  tipsOrganisation: [
     'Nakijken of het aanbod van het vormingsbeleid voldoende is afgestemd op de noden van de medewerkers.'
  ],
  tipsIndividuals: 
   [ 'Interne of externe professionalisering aanbieden die aansluit bij de specifieke noden van leraren: vb. hoe omgaan met zelfstandigheidsdidactiek? Hoe leerlingen motiveren? Hoe aan de relatie leerling-leraar werken?' ] }
,
{ key: '10ec749a-8638-4886-9d80-b95acd45913d',
  description: 'Ik werk op een positieve manier mee aan de persoonlijke en sociale ontwikkeling van de leerlingen.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'TEACHERS' ],
  screenOrder: 11,
  reportOrder: 4,
  tipsOrganisation: [
     'Nakijken of het aanbod van het vormingsbeleid voldoende is afgestemd op de noden van de medewerkers.'
  ],
  tipsIndividuals: 
   [ 'Interne of externe professionalisering aanbieden die aansluit bij de specifieke noden van leraren: vb. hoe omgaan met zelfstandigheidsdidactiek? Hoe leerlingen motiveren? Hoe aan de relatie leerling-leraar werken?' ] }
,
{ key: '341d72c9-571d-441e-83eb-c7c0844922e9',
  description: 'Ik moet veel taken opnemen die niet tot mijn (les-)opdracht behoren.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 16,
  reportOrder: 5,
  tipsOrganisation: [
     'Werken aan open en transparante communicatiecultuur',
     'Taken evenredig spreiden over de personeelsleden'
  ],
  tipsIndividuals: 
   [ 'Haalbare prioriteiten/opdrachten vastleggen en de personeelsleden de ruimte en de tijd geven om aan die prioriteiten/opdrachten te werken',
     'Opdat leraren goed zouden kunnen lesgeven moet er een goede balans aanwezig zijn tussen privé en werk waardoor er geen of weinig gevoel van negatieve werkdruk is. Denk na over hoe je er kan voor zorgen dat deze balans er is of hoe je deze balans kan bewaken.',
     'Taken evenredig spreiden over de personeelsleden',
     'Voldoende feedback geven op de opdracht van leraren en ondersteunend personeel' ] }
,
{ key: '52f72a99-078c-47d6-9603-b1b632f03ae2',
  description: 'Vernieuwingen in mijn takenpakket zijn voor mij een positieve uitdaging.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 21,
  reportOrder: 6,
  tipsOrganisation: [
     'Nakijken of het aanbod van het vormingsbeleid voldoende is afgestemd op de noden van de medewerkers.'
  ],
  tipsIndividuals: 
   [ 'Interne of externe professionalisering aanbieden die aansluit bij de specifieke noden van leraren: vb. hoe omgaan met zelfstandigheidsdidactiek? Hoe leerlingen motiveren? Hoe aan de relatie leerling-leraar werken?' ] }
,
{ key: '898dfd86-8a78-4732-a54a-9e471a3b5965',
  description: 'Door de stress op school heb ik vaak last van lichamelijke klachten.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 26,
  reportOrder: 7,
  tipsOrganisation: [
     'Investeer in sensibilisering van medewerkers rond belang van gezonde leefgewoontes, voldoende slaap, lichaamsbeweging en gezonde voeding.'
  ],
  tipsIndividuals: 
   [ 'Samen nadenken hoe de mentale weerbaarheid om de dagelijkse problemen het hoofd te kunnen bieden versterkt kan worden?',
     'Opdat leraren goed zouden kunnen lesgeven moet er een goede balans aanwezig zijn tussen privé en werk waardoor er geen of weinig gevoel van negatieve werkdruk is. Denk na over hoe je er kan voor zorgen dat deze balans er is of hoe je deze balans kan bewaken.' ] }
,
{ key: '9c976a12-90ab-4355-adb4-dd1e808ade67',
  description: 'Door de stress op school presteer ik niet op mijn niveau op school.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 31,
  reportOrder: 8,
  tipsOrganisation: [
     'Investeer in sensibilisering van medewerkers rond belang van gezonde leefgewoontes, voldoende slaap, lichaamsbeweging en gezonde voeding.'
  ],
  tipsIndividuals: 
   [ 'Samen nadenken hoe de mentale weerbaarheid om de dagelijkse problemen het hoofd te kunnen bieden versterkt kan worden?',
     'Opdat leraren goed zouden kunnen lesgeven moet er een goede balans aanwezig zijn tussen privé en werk waardoor er geen of weinig gevoel van negatieve werkdruk is. Denk na over hoe je er kan voor zorgen dat deze balans er is of hoe je deze balans kan bewaken.' ] }
,
{ key: '414e72ac-1f80-4eb8-b8fe-0e6c55fb6664',
  description: 'Ik heb het naar mijn zin op deze school.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 40,
  reportOrder: 10,
  tipsOrganisation: [
     'Doe een grondiger onderzoek naar de oorzaak.'
  ],
  tipsIndividuals: [] }
,
{ key: '67835b54-aff0-4a4d-9eab-55de0cb21bfa',
  description: 'Ik ben gelukkig in mijn job.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 43,
  reportOrder: 11,
  tipsOrganisation: [
     'Voorzie voldoende beslissingsruimte en ontplooiingsmogelijkheden.'
  ],
  tipsIndividuals: 
   [ 'Opdat leraren goed zouden kunnen lesgeven moet er een goede balans aanwezig zijn tussen privé en werk waardoor er geen of weinig gevoel van negatieve werkdruk is. Denk na over hoe je er kan voor zorgen dat deze balans er is of hoe je deze balans kan bewaken.' ] }
,
{ key: 'bae6fea1-41c9-4740-9b8a-56f8287970af',
  description: 'Er bestaat geen beter beroep dan het mijne.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 46,
  reportOrder: 12,
  tipsOrganisation: [
     'Voorzie voldoende beslissingsruimte en ontplooiingsmogelijkheden.'
  ],
  tipsIndividuals: [
     'Zorg voor een goede balans tussen privé en werk waardoor er minder een gevoel is van negatieve werkdruk.'
  ] }
,
{ key: '1a84ee14-ff0f-4e09-80d9-c082860bb684',
  description: 'Ik voel me minder betrokken bij mijn werk, ik zie het nut er niet van in.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 50,
  reportOrder: 13,
  tipsOrganisation: ['Formele en informele functioneringsgesprekken plannen. Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur'],
  tipsIndividuals: 
   [ 'Voldoende feedback geven op de opdracht van leraren en ondersteunend personeel' ] }
,
{ key: 'aac61cde-3fd0-428c-a72c-11ddcd5edcb4',
  description: 'Ik ben cynischer geworden over de effecten van mijn werk.',
  componentCode: 'AI',
  component: 'ARBEIDSINHOUD',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 13,
  reportOrder: 14,
  tipsOrganisation: ['Formele en informele functioneringsgesprekken plannen. Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur'],
  tipsIndividuals: 
   [ 'Voldoende feedback geven op de opdracht van leraren en ondersteunend personeel' ] }
,
{ key: '283afef8-446b-4f81-b137-a3f7320ca150',
  description: 'Een deel van mijn afwezigheden werd veroorzaakt door mijn werk.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 52,
  reportOrder: 15,
  tipsOrganisation: [
     'Voorzie voldoende beslissingsruimte en ontplooiingsmogelijkheden.'
  ],
  tipsIndividuals: [
     'Zorg voor een goede balans tussen privé en werk waardoor er minder een gevoel is van negatieve werkdruk.'
  ] }
,
{ key: 'e1e0db9e-b82a-418e-8187-8d653874fbd4',
  description: 'Ik voel me \'opgebrand\' doordat het werk me emotioneel uitput',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 57,
  reportOrder: 16,
  tipsOrganisation: [
     'Investeer in sensibilisering van medewerkers rond belang van gezonde leefgewoontes, voldoende slaap, lichaamsbeweging en gezonde voeding.'
  ],
  tipsIndividuals: [] }
,
{ key: '8fb8c079-1d82-4ee6-bb2f-79b82c6ef9ff',
  description: 'Ik sta \'s morgens vermoeid op en heb geen zin om naar mijn werk te gaan.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 12,
  reportOrder: 17,
  tipsOrganisation: [
     'Investeer in sensibilisering van medewerkers rond belang van gezonde leefgewoontes, voldoende slaap, lichaamsbeweging en gezonde voeding.'
  ],
  tipsIndividuals: [] }
,
{ key: '13c65a29-4889-424c-9195-15511e9a8482',
  description: 'De infrastructuur stimuleert een positief werkklimaat',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 17,
  reportOrder: 18,
  tipsOrganisation: [],
  tipsIndividuals: 
   [ 'Aandacht schenken aan de omgeving waarbinnen personeelsleden moeten werken. (lucht, verlichting, temperatuur, geluid, minimale materiële uitrusting...)' ] }
,
{ key: '1f3f9bb1-5121-4644-9648-3206a0961339',
  description: 'De lokalen en het schoolgebouw liggen er vaak vuil en onhygiënisch bij.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 22,
  reportOrder: 19,
  tipsOrganisation: [],
  tipsIndividuals: 
   [ 'Personeel motiveren om mee te werken aan een veilig en gezond werkmilieu betreft orde, netheid en hygiëne.' ] }
,
{ key: 'f010aa49-f51a-4fcb-acd6-ae2b220a0e59',
  description: 'Ik kan op school over een aangepaste infrastructuur beschikken.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 27,
  reportOrder: 20,
  tipsOrganisation: [],
  tipsIndividuals: 
   [ 'Aandacht schenken aan de omgeving waarbinnen personeelsleden moeten werken. (lucht, verlichting, temperatuur, geluid, minimale materiële uitrusting...)' ] }
,
{ key: 'ca1f8c61-4d5c-437d-b309-3203d7b876b8',
  description: 'Ik kan op school over  aangepast materiaal beschikken.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 32,
  reportOrder: 21,
  tipsOrganisation: [],
  tipsIndividuals: 
   [ 'Aandacht schenken aan de omgeving waarbinnen personeelsleden moeten werken. (lucht, verlichting, temperatuur, geluid, minimale materiële uitrusting...)' ] }
,
{ key: 'd999b544-3b3f-4ff6-b340-8a81e19331d9',
  description: 'Ik ken de wettelijke procedure die moet gevolgd worden bij problemen rond psychosociale risico\'s (pesten, geweld, ongewenst seksueel gedrag, stress, burn-out)',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 37,
  reportOrder: 22,
  tipsOrganisation: 
   [ 'Het nodige doen voor de goede werking van interne overlegorganen',
     'Duidelijke communicatie voeren over de procedure die moet gevolgd worden bij problemen rond psycho-sociale risico’s' ],
  tipsIndividuals: [
     'Voorzie gestructureerde informatiemomenten voor medewerkers betreffende de procedures psychosociale risico\'s.'
  ] }
,
{ key: 'd1805d8e-30c4-4619-b39a-8615df48ad22',
  description: 'Ik krijg ruimte om nieuwe ideeën of projecten uit te proberen.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 41,
  reportOrder: 23,
  tipsOrganisation: 
   [ 'Toelaten van gedeeld leiderschap; durven verantwoordelijkheden te leggen bij de personeelsleden en de teams',
     'De contouren voor haalbare prioriteiten/opdrachten vastleggen en de personeelsleden de ruimte en de tijd geven om aan die prioriteiten/opdrachten invulling te geven' ],
  tipsIndividuals: [] }
,
{ key: '935112f6-b4b6-4152-8550-3422154980bc',
  description: 'Sinds het laatste jaar heb ik me fysiek bedreigd gevoeld.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 47,
  reportOrder: 24,
  tipsOrganisation: [],
  tipsIndividuals: [ 'aanbod assertiviteitstrainingen' ] }
,
{ key: 'e4a46a4d-7f31-415c-95f2-61bfe13aac65',
  description: 'Sinds het laatste jaar krijg ik verbale bedreigingen.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 15,
  reportOrder: 25,
  tipsOrganisation: [],
  tipsIndividuals: [ 'aanbod assertiviteitstrainingen' ] }
,
{ key: 'e4a46a4d-7f31-415c-95f2-61bfe13aac64',
  description: 'Op onze school bestaat er een systeem van registreren van ongewenst gedrag (onder meer geweld) door derden.',
  componentCode: 'AOM',
  component: 'ARBEIDSOMSTANDIGHEDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 62,
  reportOrder: 26,
  tipsOrganisation: [ 'Duidelijke communicatie voeren over de procedure die moet gevolgd worden bij problemen rond psycho-sociale risico\’s' ],
  tipsIndividuals: [] }
,
{ key: '15ab75d9-9307-4aaa-a90e-505c9bbaf1ba',
  description: 'Bij een gemeenschappelijke taak voelt iedereen zich betrokken.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 3,
  reportOrder: 26,
  tipsOrganisation: [],
  tipsIndividuals: [
     'Motiveer je personeel om een verantwoordelijkheid op te nemen.'
  ] }
,
{ key: '794eb292-ded2-41f9-8719-364d1f92c87f',
  description: 'In onze school werken wij samen.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 48,
  reportOrder: 27,
  tipsOrganisation: 
   [ 'Mogelijkheden scheppen tot gezamenlijk informeel overleg',
     'Toelaten van gedeeld leiderschap; durven verantwoordelijkheden te leggen bij de personeelsleden en de teams' ],
  tipsIndividuals: [
     'Motiveer je personeel om een verantwoordelijkheid op te nemen.'
  ] }
,
{ key: '25a91f0a-2ded-48e8-b546-d9d244e37f98',
  description: 'Mijn job laat mij voldoende vrije tijd.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 53,
  reportOrder: 28,
  tipsOrganisation: [
     'Denk na over hoe je er kan voor zorgen dat de balans werk/privé er is of hoe je deze balans kan bewaken.'
  ],
  tipsIndividuals: 
   [ 'Taken evenredig spreiden over de personeelsleden',
     'Duidelijkheid verschaffen in de taakverdeling van het personeel. Wie doet wat? Wie volgt op?' ] }
,
{ key: '8402311d-d55e-4e98-8255-3c9c904be22d',
  description: 'Als er een probleem is, kan ik terecht bij de directie/leidinggevenden.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 58,
  reportOrder: 29,
  tipsOrganisation: [
     'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'
  ],
  tipsIndividuals: 
   [ 'Zorgen voor de nodige begeleiding en ondersteuning van leraren in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)' ] }
,
{ key: '2feef774-f0f8-4a69-96b9-83258aa5c296',
  description: 'Wij hebben een mogelijkheid om inspraak te hebben in het schoolbeleid.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 23,
  reportOrder: 30,
  tipsOrganisation: [
     'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'
  ],
  tipsIndividuals: 
   [ 'Leraren betrekken bij de samenstelling van de klassen',
     'Leraren betrekken bij het opmaken van de uurroosters door bijv. Desiderata op te vragen',
     'Waar het kan structureel vak/werkgroepoverleg binnen de uurroosters faciliteren' ] }
,
{ key: 'c7d1849f-8dff-42ab-a1bf-f27815713140',
  description: 'De directie houdt rekening met onze bekommernissen.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 28,
  reportOrder: 31,
  tipsOrganisation: [
     'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'
  ],
  tipsIndividuals: 
   [ 'Leraren betrekken bij het opmaken van de uurroosters door bijv. Desiderata op te vragen',
     'Zorgen voor de nodige begeleiding en ondersteuning van leraren in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)' ] }
,
{ key: 'd5a4520c-a564-435d-8cd8-f3c8ce8d7e7f',
  description: 'Men licht ons tijdig in over geplande beslissingen van de directie.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 33,
  reportOrder: 32,
  tipsOrganisation: [ 'werken aan open en transparante communicatiecultuur' ],
  tipsIndividuals: [] }
,
{ key: 'cf9c30cb-56eb-423d-bd22-c84946450bf2',
  description: 'Beginnende collega’s worden op mijn school begeleid.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 38,
  reportOrder: 33,
  tipsOrganisation: [
     'Uitwerken van een onthaalbeleid.'
  ],
  tipsIndividuals: 
   [ 'Het onthaal verzorgen van beginnende leraren en zorgen voor begeleiding en ondersteuning, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)',
     'Extra ondersteuning bieden aan de nieuwe leden van het personeel' ] }
,
{ key: 'cd687e5d-e1f1-4ec8-9ccf-72ea528c6292',
  description: 'Als er een probleem is, kan ik terecht bij mijn schoolbestuur.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 55,
  reportOrder: 34,
  tipsOrganisation: [ 'werken aan open en transparante communicatiecultuur' ],
  tipsIndividuals: [] }
,
{ key: '35f44b3c-7e1b-4d30-9539-a74eb6f16df6',
  description: 'Ik heb de klas(sen) in de hand.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'TEACHERS' ],
  screenOrder: 44,
  reportOrder: 35,
  tipsOrganisation: ['Een aangepaste vorming voorzien om de competentie te verhogen.'],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: '66cb49c2-b1a0-453b-ab48-acd867fded2c',
  description: 'De leerlingen luisteren naar wat ik zeg.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 9,
  reportOrder: 36,
  tipsOrganisation: [
     'Een aangepaste vorming voorzien om de competentie te verhogen.'
  ],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: '7e27ebcc-b7b5-447f-9285-46414db81ea9',
  description: 'Ik heb een goede band met de leerlingen',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 14,
  reportOrder: 37,
  tipsOrganisation: [
     'Een aangepaste vorming voorzien om de competentie te verhogen.'
  ],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: '600b9044-7c25-47f4-a39c-341779a92d7f',
  description: 'Ik krijg waardering van de leerlingen.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 19,
  reportOrder: 38,
  tipsOrganisation: [
     'Een aangepaste vorming voorzien om de competentie te verhogen.'
  ],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: 'd157089e-ca47-40f4-8d58-1f141f400b86',
  description: 'Het lukt mij om zelfs met de moeilijkste leerlingen op een positieve manier om te gaan.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS' ],
  screenOrder: 24,
  reportOrder: 39,
  tipsOrganisation: [
     'Een aangepaste vorming voorzien om de competentie te verhogen.'
  ],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: 'b497d9d8-9ce1-4bdd-bbd7-58a82757a490',
  description: 'Als ik het op school eens minder goed zie zitten, vind ik steun bij collega\'s.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 29,
  reportOrder: 40,
  tipsOrganisation: 
   [ 'Investeer in communicatievaardigheden, versterk de vaardigheid voor het geven van feedback, het geven van positieve bevestiging' ],
  tipsIndividuals: [
     'Stimuleren van de sociale steun van collega\'s.'
  ] }
,
{ key: '052b9f22-eb7f-4f99-85d4-ce6a04062476',
  description: 'De collega’s waarderen mij.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 34,
  reportOrder: 41,
  tipsOrganisation: 
   [ 'Investeer in communicatievaardigheden, versterk de vaardigheid voor het geven van feedback, het geven van positieve bevestiging' ],
  tipsIndividuals: 
   [ 'Aandacht schenken of installeren van informele momenten die de samenhorigheid tussen leraren, ondersteunend personeel, onderhoudspersoneel en directie kunnen bevorderen' ] }
,
{ key: 'a9fbdee5-a7c2-40d0-982a-1962bd821bbc',
  description: 'In onze school heerst er een sfeer van vertrouwen.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 39,
  reportOrder: 42,
  tipsOrganisation: [],
  tipsIndividuals: 
   [ 'Aandacht schenken of installeren van informele momenten die de samenhorigheid tussen personeelsleden kunnen bevorderen.' ] }
,
{ key: 'b7e0d873-2e75-4e30-bd33-7baadf42784b',
  description: 'Ik vermijd de lerarenkamer.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS' ],
  screenOrder: 42,
  reportOrder: 43,
  tipsOrganisation: [ 'Werken aan teambuilding',
   'Schenk aandacht aan een open communicatiecultuur, respectvolle omgang op het werk, aandacht voor diversiteit en sociale steun van collega\'s.' ],
  tipsIndividuals: [] }
,
{ key: '2155f62b-d0c6-4f9f-a4f9-9cffaccbab9e',
  description: 'De sfeer is verziekt door conflicten tussen personeelsleden',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 54,
  reportOrder: 44,
  tipsOrganisation: 
   [ 'Investeer in communicatievaardigheden, versterk de vaardigheid voor het geven van feedback, het geven van positieve bevestiging' ],
  tipsIndividuals: [
     'Bied de mogelijkheid van bemiddelingsgesprekken aan.'
  ] }
,
{ key: '160de23e-3421-4e6f-a481-f9d7c1735bbc',
  description: 'Conflicten op het werk wegen op mijn gezondheid.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 45,
  reportOrder: 45,
  tipsOrganisation: 
   [ 'neem contact op met externe (preventieadviseur psychosociale aspecten, bemiddeling katholiek onderwijs,...)' ],
  tipsIndividuals: [] }
,
{ key: '5d67f954-fa54-477a-b1a7-5b20b4a99cac',
  description: 'De directie ondersteunt mij in mijn groei als (onderwijs)professional',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 8,
  reportOrder: 46,
  tipsOrganisation: [
     'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'
  ],
  tipsIndividuals: 
   [ 'Interesse en waardering tonen voor het werk van medewerkers' ] }
,
{ key: '445e2058-7beb-4d46-a45a-b5ce0f578f48',
  description: 'De directie steunt mij bij conflicten met leerlingen en ouders.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 49,
  reportOrder: 47,
  tipsOrganisation: [
     'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'
  ],
  tipsIndividuals: 
   [ 'Interesse en waardering tonen voor het werk van medewerkers' ] }
,
{ key: 'b7d0cb4b-e51f-4682-ae64-6c2ed92c25e4',
  description: 'De directie weet wat er onder de leraren en het ondersteunend personeel leeft.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 2,
  reportOrder: 48,
  tipsOrganisation: [ 'werken aan open en transparante communicatiecultuur' ],
  tipsIndividuals: 
   [ 'Interesse en waardering tonen voor het werk van medewerkers' ] }
,
{ key: '62e4749a-e7da-414b-8db1-bcccdff7263a',
  description: 'De directie waardeert mijn inspanningen.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 5,
  reportOrder: 49,
  tipsOrganisation: [ 'werken aan open en transparante communicatiecultuur' ],
  tipsIndividuals: 
   [ 'Interesse en waardering tonen voor het werk van medewerkers' ] }
,
{ key: '2a60085b-8598-4b84-8fa7-44da866c56e3',
  description: 'Ik heb goede contacten met de ouders.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 56,
  reportOrder: 50,
  tipsOrganisation: [],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: '70645a4c-9a5a-40de-bbf6-df4121b4ca0b',
  description: 'De ouders tonen appreciatie voor mijn werk.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 18,
  reportOrder: 51,
  tipsOrganisation: [],
  tipsIndividuals: [
     'Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'
  ] }
,
{ key: '31aa6f06-3c32-46e2-9b6d-33ed1ec49810',
  description: 'Sinds een jaar heb ik het gevoel gepest te worden op het werk omdat een collega me bij herhaling lastig valt',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 7,
  reportOrder: 52,
  tipsOrganisation: [ 'Sensibiliseringscampagne aanpak psychosociale risico\'s' ],
  tipsIndividuals: [
     'Werk aan een meer verbindende schoolcultuur.'
  ] }
,
{ key: 'f6f7211f-30f0-4d3f-9ca4-53402b1f6f08',
  description: 'Sinds een jaar voel ik me slachtoffer van ongewenst seksueel gedrag.',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 59,
  reportOrder: 53,
  tipsOrganisation: [ 'sensibiliseringscampagne aanpak psychosociale risico\'s' ],
  tipsIndividuals: [] }
,
{ key: '3402a84b-da12-4bdc-9b8b-e190b993e37f',
  description: 'Ik weet wie op onze school als vertrouwenspersoon aangesteld is',
  componentCode: 'AR',
  component: 'ARBEIDSRELATIES',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 30,
  reportOrder: 54,
  tipsOrganisation: [ 'sensibiliseringscampagne aanpak psychosociale risico\'s' ],
  tipsIndividuals: [] }
,
{ key: 'e8485950-3e10-4919-9255-01915e746704',
  description: 'Ik krijg de kans om met mijn directie over mijn functioneren te overleggen.',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 4,
  reportOrder: 55,
  tipsOrganisation: ['Formele en informele functioneringsgesprekken plannen.',
   'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'],
  tipsIndividuals: [] }
,
{ key: '4dc5ff59-0831-41e5-bb92-f472c7f33b62',
  description: 'Op onze school krijgen we kansen om onze competenties op peil te houden en verder te ontwikkelen',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'POSITIVE',
  target: [ 'TEACHERS', 'STAFF' ],
  screenOrder: 10,
  reportOrder: 56,
  tipsOrganisation: ['Voorzie voldoende beslissingsruimte en ontplooiingsmogelijkheden.'],
  tipsIndividuals: ['interne of externe professionalisering aanbieden die aansluit bij de specifieke noden van personeelsleden.'] }
,
{ key: '42562ae6-b025-4d6e-bdad-1e5775e8f767',
  description: 'Mijn opdracht sluit aan bij mijn kwaliteiten/capaciteiten',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 51,
  reportOrder: 57,
  tipsOrganisation: ['Voorzie voldoende beslissingsruimte en ontplooiingsmogelijkheden.'],
  tipsIndividuals: [] }
,
{ key: 'c395498d-1a62-4225-96ea-c7a19c4e05dd',
  description: 'Ik krijg de kans om tijdens de werkuren nascholing te volgen.',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 20,
  reportOrder: 58,
  tipsOrganisation: ['Voorzie voldoende beslissingsruimte en ontplooiingsmogelijkheden.'],
  tipsIndividuals: 
   [ 'Interne of externe professionalisering aanbieden die aansluit bij de specifieke noden van leraren: vb. hoe omgaan met zelfstandigheidsdidactiek? Hoe leerlingen motiveren? Hoe aan de relatie leerling-leraar werken?' ] }
,
{ key: '044507b8-c6c7-4440-95cf-989edb9d3377',
  description: 'Aan het einde van de zomervakantie zie ik de leerlingen graag terug .',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'POSITIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 25,
  reportOrder: 59,
  tipsOrganisation: ['Een aangepaste vorming voorzien om de competentie te verhogen.'],
  tipsIndividuals: ['Zorgen voor de nodige begeleiding en ondersteuning van personeelsleden in het algemeen, eventueel in samenwerking met externen (vb. Pedagogische begeleidingsdienst)'] }
,
{ key: 'dc22807e-93fd-420d-b0ca-45631864ad81',
  description: 'Ik maak mij zorgen over mijn werkzekerheid binnen onze school.',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 60,
  reportOrder: 60,
  tipsOrganisation: [ 'werken aan open en transparante communicatiecultuur' ],
  tipsIndividuals: 
   [ 'Het belang van een transparante werking vooropstellen. Duidelijk, snel en regelmatig communiceren over alles wat personeelsleden aanbelangt.' ] }
,
{ key: '406d7f12-0cc3-457f-9d8c-eec650a95e4c',
  description: 'Ik denk eraan om het onderwijs te verlaten.',
  componentCode: 'AV',
  component: 'ARBEIDSVOORWAARDEN',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 35,
  reportOrder: 61,
  tipsOrganisation: ['Formele en informele functioneringsgesprekken plannen.',
      'Schenk voldoende aandacht aan de stijl van leiding geven en zorg voor een open communicatiecultuur.'],
  tipsIndividuals: ['Het belang van een transparante werking vooropstellen. Duidelijk, snel en regelmatig communiceren over alles wat personeelsleden aanbelangt.'] 
},
{ key: '506d7f12-0cc3-457f-9d8c-eec650a95e4c',
  description: 'Altijd online bereikbaar moeten zijn voor directie, collega’s, leerlingen en/of ouders (bv via Smartschool) bezorgt mij stress.',
  componentCode: 'AOR',
  component: 'ARBEIDSORGANISATIE',
  impact: 'NEGATIVE',
  target: [ 'DIRECTORS', 'TEACHERS', 'STAFF' ],
  screenOrder: 63,
  reportOrder: 34,
  tipsOrganisation: ['Organiseer regelmatige tijdstippen en telkens de werknemersvertegenwoordiging erom verzoekt, een overleg over deconnectie van het werk en het gebruik van digitale communicatiemiddelen'],
  tipsIndividuals: [] 
}
],
getQuestion: function(key) {
  let qst;
  this.survey.forEach(q => {
    if (q.key === key) {
      qst = q;
    }
  });
  return qst;
}
};
