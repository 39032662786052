
module.exports = ['$rootScope', '$http', '$q', 'settings', function($rootScope, $http, $q, settings) {

  var url = settings.apisAndUrls.idp + '/answers';

  this.loadQuestion = function(qstId) {
    var username = $rootScope.username ? $rootScope.username : 'default';

    var d = $q.defer();

    // $http.get(url + '?questionId=' + qstId + '&username=' + username).then(function(resp) {
    //   var results = resp.data.results.map(function(r) {
    //     return r.$$expanded;
    //   });
    //
    //   d.resolve(results);
    // });
    d.resolve([]);

    return d.promise;
  };

  this.saveQuestion = function(qstId, responses, answerPermalink) {

    var key = answerPermalink ? answerPermalink.substr(answerPermalink.substr(1).indexOf('/')+2, answerPermalink.length) : getUUID();
    var answer = {key: key, username: $rootScope.username, questionId: qstId, answer: responses};

    if (!answer.username) {
      answer.username = 'default';
    }

    $http({
      method: 'PUT',
      url: url + '/' + key,
      data: answer,
      contentType: 'application/json',
      dataType: 'json'
    }).success(function(data, status) {
      console.log('PUT OK');
    }).error(function(resp, status) {
      console.log('PUT Error');
    });
  };


  function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8); //eslint-disable-line
      return v.toString(16);
    });
  };

  return this;
}];
