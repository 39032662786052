require('./css/stepFooter.scss');

class StepFooter {

    constructor($scope){
      this.$scope = $scope;
    }
  
    // injection here
    static get $inject() {
      return [
        '$scope'
      ];
    }
    
    
    glyphiconIcon() {
      let glyClass = this.nextIcon ? this.nextIcon : 'glyphicon-chevron-right';

      glyClass += this.nextTextOnRight ? ' margin-right' : ' margin-left';

      return glyClass;
    }

    nextHref() {
      return this.next ? ('#!/' + this.next) : '';
    }

    onNext() {
      this.$scope.$emit('step_footer_next');
    }

    onCancel() {
      this.$scope.$emit('step_footer_cancel');
    }
    
    $onInit() {
      return (async () => {
        this.nextText = this.nextText || 'Verder gaan';

        this.nextTextOnRight = this.nextTextOnRight || false;  
      })();
    }
  
    $onChanges(changes) {
      
    }
  }
  
  
  module.exports = {
    template: require('./templates/stepFooter.html'),
    controllerAs: 'ctrl',
    controller: StepFooter,
    bindings: {
      cancel: '@?',
      next: '@?',
      nextText: '@?',
      nextTextOnRight: '@?',
      nextIcon: '@?'
    }
  };