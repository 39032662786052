
require( './css/bootstrap-datepicker3.css' );

require( './bootstrap-datepicker.js' );
require( './bootstrap-datepicker.nl-BE.js' );

var module =
  angular.module( 'vsko.datepicker', [] );

module.directive( 'datePicker', require( './angular-vsko-datepicker.js' ) );
module.directive( 'datePickerInline', require( './angular-vsko-datepicker-inline.js' ) );

module.exports = module;
