require('./upload-participants.scss');


class UploadParticipants {

  constructor($scope, $parse, $element){
    this.$scope = $scope;
    this.$parse = $parse;
    this.$element = $element;

    // needed for IE11
    if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (fileData) {
         var binary = "";
         var pt = this;
         var reader = new FileReader();      
         reader.onload = function (e) {
             var bytes = new Uint8Array(reader.result);
             var length = bytes.byteLength;
             for (var i = 0; i < length; i++) {
                 binary += String.fromCharCode(bytes[i]);
             }
          //pt.result  - readonly so assign binary
          pt.content = binary;
          $(pt).trigger('onload');
        }
        reader.readAsArrayBuffer(fileData);
      }
    }

    // Pass upload event to callback    
    $('input:file', $element[0]).on("change", function(changeEvent) {
      
      var reader = new FileReader();
      reader.onload = function(e) {
        
        var file = !e ? reader.content : e.target.result;
        var workbook = XLSX.read(file, { type: "binary" });
  
        // Get the first worksheet as JSON
        var sheetName = workbook.SheetNames[0];
        var ws = update_sheet_range(workbook.Sheets[sheetName]);

        //console.log(JSON.stringify(ws));
        const sheet = XLSX.utils.sheet_to_json(ws);
  
        console.log('Sheet:', sheet)

        $scope.$emit('uploaded_participants', sheet);
      };
      reader.readAsBinaryString(changeEvent.target.files[0]);

      $('input:file', this).val('');
    });

    // avoid problems with xlsx formats and bad reported sheet size
    // ref: https://github.com/SheetJS/js-xlsx/issues/764
    function update_sheet_range(ws) {
      var range = {s:{r:20000000, c:20000000},e:{r:0,c:0}};
      Object.keys(ws).filter(function(x) { return x.charAt(0) != "!"; }).map(XLSX.utils.decode_cell).forEach(function(x) {
        range.s.c = Math.min(range.s.c, x.c); range.s.r = Math.min(range.s.r, x.r);
        range.e.c = Math.max(range.e.c, x.c); range.e.r = Math.max(range.e.r, x.r);
      });
      ws['!ref'] = XLSX.utils.encode_range(range);
      return ws;
    }
  }

  // injection here
  static get $inject() {
    return [
      '$scope',
      '$parse',
      '$element'
    ];
  }

  
  $onInit() {
    return (async () => {
    })();
  }

  $onChanges(changes) {
    
  }
}


module.exports = {
  template: require('./upload-participants.html'),
  transclude: true,
  controllerAs: 'ctrl',
  controller: UploadParticipants
};