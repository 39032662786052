
var module = angular.module('kathondvla.qti', []);

module.constant('events', require('./utils/events.js'));

module.service('qtiCommunication', require('./services/qti-communication.js'));
module.service('qtiLoader', require('./services/qti-loader.js'));

module.directive('qtiQuestion', require('./directives/qtiQuestion.js'));
module.directive('qtiModel', require('./directives/qtiModel.js'));
module.directive('qtiDebug', require('./directives/qtiDebug.js'));

module.directive('qtiAssessmentitem', require('./directives/types/assessmentItem.js'));
module.directive('qtiChoiceinteraction', require('./directives/types/choiceInteraction.js'));
module.directive('qtiSimplechoice', require('./directives/types/simpleChoice.js'));

module.exports = module;
