require('./css/stepFeedback.scss');

class StepFeedback {

    constructor(){
    }
  
    // injection here
    static get $inject() {
      return [
      ];
    }
    
    messageType() {
        return this.message && this.message.class ? this.message.class : 'error-message';
      }

    messagePosition() {
        return this.position && this.position === 'left' ? 'align-left' : 'align-right';
      }
    
    $onInit() {
      return (() => {
      })();
    }
  
    $onChanges(changes) {
      
    }
  }
  
  
  module.exports = {
    template: require('./templates/stepFeedback.html'),
    controllerAs: 'ctrl',
    controller: StepFeedback,
    bindings: {
        message: '<',
        position: '@?'
    }
  };