
// just to test the babel-plugin-angularjs for auto inject
// note: the plugin works when we create the component inline like this and not by require
// class Test {
  
//     constructor($rootScope, $scope, $location, dateUtils, Utils) {
//         console.log('Test component const');
//         dateUtils.stripTime('2018-08-05');
//     }
// }

angular.module('appComponents', [])


.component('stepHeader', require('./wizard-step/step-header.js'))
.component('stepFooter', require('./wizard-step/step-footer.js'))
.component('stepFeedback', require('./wizard-step/step-feedback.js'))
.component('backToSurveys', require('./wizard-step/back-to-surveys.js'))

.component('uploadParticipants', require('./upload-participants'))

.component('reportBox', require('./report-box'))

.component('tableRow', require('./table-row'))

// .component('testC', {
//     template: require('./test-c/test-inject.html'),
//     controllerAs: 'ctrl',
//     controller: Test
// })

.component('testI', require('./test-c'))


module.exports = 'appComponents';
