// require('./welcome.css');
require('./school.css');
require('./locations.scss');
require('./directors.css');
require('./survey-selection.css');
require('./intake.css');
require('./groups.css');
require('./participants.css');
require('./mail.css');
require('./overview.css');
require('./status.css');
require('./survey.css');

require('./report/menu.css');
require('./report/dashboard.css');
require('./report/dashboardTeam.css');
require('./report/participants.css');
require('./report/attentionPoints.css');