module.exports = [ 'events', 
  function (events) {
    return {
      restrict: 'E',
      template: require('../templates/simpleChoice.html'),
      replace: true,
      scope: {
        value: '@?',
        identifier: '@?'
      },
      link: function(scope, element, attrs)
      {
        let isSelected = false;
        
        // alert parent interaction of this choice creation
        let event = new Event(events.REGISTER_CHOICE, {bubbles: true});
        //event.detail = {elementApi: api};
        element[0].dispatchEvent(event);

        /**
         * On click: selected option
         */
        scope.selection = function() {
          isSelected = !isSelected;

          // will notify the parent interaction
          const detail = {identifier: scope.identifier, 
                          value: scope.value, 
                          elementApi: api};

          let event = new Event(events.CHOICE_SELECTED, {bubbles: true, detail : detail});
          try {
            // NOT IE11 should set ev.details like this
            event.detail = detail;
          } catch(e) {
           // IE11 we dont care 
          }
          element[0].dispatchEvent(event);
        }

        scope.selectedClass = function() {
          return isSelected ? 'question-choice-selected' : '';
        }

        let api = {
          setStatus: (status) => {
            isSelected = status;
          },
          isSelected: () => {
            return isSelected;
          },
          element: element[0]
        };
      }
    };
}];