module.exports = ['constants', 'dateUtils', 'Utils', 'Reports', 'Survey', 'Organisations', 'dataHelpers',
function (constants, dateUtils, Utils, Reports, Survey, Organisations, dataHelpers) { //eslint-disable-line

    const emptyCellsArray = (count) => {
      const arr = [];
      for (let i = 0; i < count; i += 1) {
        arr.push('');
      }
      return arr;
    };

    const getSchoolCount = (surveyData) => {
      return [... new Set(surveyData.map(survey => survey.school.href))].length;
    };

    const getParticipantCount = (surveyData) => {
      return surveyData.reduce((number, survey) => {
        return survey.groups.reduce((n, group) => {
          return n + group.participants.filter(participant => participant.submitted).length;
        }, number);
      }, 0);
    };

    const percentagesHeaders = [
      '% helemaal niet akkoord',
      '% niet akkoord',
      '% ik weet het niet / niet van toepassing',
      '% akkoord',
      '% helemaal akkoord'
    ];

    const averageAndStDevHeaders = [
      'Gemiddelde % helemaal niet akkoord',
      'Standaard afwijking % helemaal niet akkoord',
      'Gemiddelde % niet akkoord',
      'Standaard afwijking % niet akkoord',
      'Gemiddelde % ik weet het niet / niet van toepassing',
      'Standaard afwijking % ik weet het niet / niet van toepassing',
      'Gemiddelde % akkoord',
      'Standaard afwijking % akkoord',
      'Gemiddelde % helemaal akkoord',
      'Standaard afwijking % helemaal akkoord'
    ];

    const findSurveyQuestion = (components, questionKey) => {
      let surveyQst;
      components.forEach(component => {
        const qst = component.questions.find(q => q.key === questionKey);
        if (qst) {
          surveyQst = qst;
        }
      });
      return surveyQst;
    };

    const getPercentage = (section) => {
      return section.percentage ? section.percentage : 0;
    };

    const average = (totalAnswers, totalAnswersOfType = 0) => {
      const avg = totalAnswersOfType / totalAnswers * 100;
      return avg ? +avg.toFixed() : 0;
    };

    const averageSimple = (values) => {
      const sum = values.reduce((s, value) => {
        return s + value;
      }, 0);

      const avg = sum / values.length;
      return avg ? +avg.toFixed() : 0;
    };

    const standardDeviation = (values, totalAnswers, totalAnswersOfType = 0) => {
      const avg = average(totalAnswers, totalAnswersOfType);

      const squareDiffs = values.map((value) => {
        const diff = average(value.totalAnswers, value.countedAnswers) - avg;
        const sqrDiff = diff * diff;
        return sqrDiff;
      });

      const avgSquareDiff = averageSimple(squareDiffs);

      const stdDev = Math.sqrt(avgSquareDiff);
      return stdDev ? +stdDev.toFixed() : 0;
    };

    const getAverageAndStandardDeviationCells = (question, surveys) => {
      const surveysSections = [];
      let totalAnswers = 0;

      // fill an array with all the surveys section for the given question. Only those with answers
      surveys.forEach(s => {
        const surveyQst = findSurveyQuestion(s.components, question.key);

        if (surveyQst && surveyQst.answers.length > 0) {
          surveysSections.push(surveyQst.sections);
          totalAnswers += surveyQst.answers.length;
        } else if (surveyQst && surveyQst.answers.length === 0) {
          surveysSections.push(
            surveyQst.sections.map(() => ({ percentage: 0, countedAnswers: 0 }))
          );
        }
      });

      // group answer type percentages in different arrays
      const totallyDisagreeValues = [];
      let totallyDisagreeCount = 0;
      const disagreeValues = [];
      let disagreeCount = 0;
      const notApplicableValues = [];
      let notApplicableCount = 0;
      const agreeValues = [];
      let agreeCount = 0;
      const totallyAgreeValues = [];
      let totallyAgreeCount = 0;

      surveysSections.forEach(sections => {
        // totallyDisagreeValues.push(sections[0].percentage);
        totallyDisagreeValues.push({
          totalAnswers: sections[0].totalAnswers,
          countedAnswers: sections[0].countedAnswers
        });
        totallyDisagreeCount += sections[0].countedAnswers;
        // disagreeValues.push(sections[1].percentage);
        disagreeValues.push({
          totalAnswers: sections[1].totalAnswers,
          countedAnswers: sections[1].countedAnswers
        });
        disagreeCount += sections[1].countedAnswers;
        // notApplicableValues.push(sections[2].percentage);
        notApplicableValues.push({
          totalAnswers: sections[2].totalAnswers,
          countedAnswers: sections[2].countedAnswers
        });
        notApplicableCount += sections[2].countedAnswers;
        // agreeValues.push(sections[3].percentage);
        agreeValues.push({
          totalAnswers: sections[3].totalAnswers,
          countedAnswers: sections[3].countedAnswers
        });
        agreeCount += sections[3].countedAnswers;
        // totallyAgreeValues.push(sections[4].percentage);
        totallyAgreeValues.push({
          totalAnswers: sections[4].totalAnswers,
          countedAnswers: sections[4].countedAnswers
        });
        totallyAgreeCount += sections[4].countedAnswers;
      });

      // get the average and standard deviation for each type of answer
      const cells = [
        average(totalAnswers, totallyDisagreeCount),
        standardDeviation(totallyDisagreeValues, totalAnswers, totallyDisagreeCount),
        average(totalAnswers, disagreeCount),
        standardDeviation(disagreeValues, totalAnswers, disagreeCount),
        average(totalAnswers, notApplicableCount),
        standardDeviation(notApplicableValues, totalAnswers, notApplicableCount),
        average(totalAnswers, agreeCount),
        standardDeviation(agreeValues, totalAnswers, agreeCount),
        average(totalAnswers, totallyAgreeCount),
        standardDeviation(totallyAgreeValues, totalAnswers, totallyAgreeCount)
      ];

      return cells;
    };


    const xlsxDataGroups = [
      {
        groupKey: 'surveyGroup',
        groupMethods: {
          getColumnTitle: () => {
            return [
              ...emptyCellsArray(1),
              'Bevraging percentages',
              ...emptyCellsArray(4)
            ];
          },
          getColumnSecondLevelTitle: () => ['Uitspraak', ...percentagesHeaders],
          getPercentagesCells: (opts) => {
            const { question, situation } = opts;

            let sections = question.sections;

            // turn back to original percentages in sections for impact NEGATIVE questions
            // or situation after (needed for excel #961093318)
            if (question.impact === 'NEGATIVE' || situation === 'after') {
              const newSectionsOrder = [];
              // pa = DO_NOT_AGREE_AT_ALL|DO_NOT_AGREE|DO_NOT_KNOW|AGREE|TOTALLY_AGREE
              constants.possibleAnswers.forEach((pa, idx) => {
                newSectionsOrder.push({
                  percentage: question.sections[question.sections.length - 1 - idx].percentage,
                  countedAnswers: question.sections[question.sections.length - 1 - idx].countedAnswers,
                  totalAnswers: question.sections[question.sections.length - 1 - idx].totalAnswers
                });
              });
              sections = newSectionsOrder;
            }

            return [
              getPercentage(sections[0]),
              getPercentage(sections[1]),
              getPercentage(sections[2]),
              getPercentage(sections[3]),
              getPercentage(sections[4])
            ];
          }
        },
        columnsForResultsNeeded: 5,
        columnsBackgroundColor: 'E1E1E1'
      },
      {
        groupKey: 'schoolbestuurGroup',
        groupMethods: {
          getColumnTitle: (surveys) => {
            return [
              `Bestuur (deelname ${getSchoolCount(surveys.schoolbestuurGroup)} instellingen met ${getParticipantCount(surveys.schoolbestuurGroup)} deelnemers)`,
              ...emptyCellsArray(9)
            ];
          },
          getColumnSecondLevelTitle: () => averageAndStDevHeaders,
          getPercentagesCells: (opts) => getAverageAndStandardDeviationCells(opts.question, opts.surveys)
        },
        columnsForResultsNeeded: 10,
        columnsBackgroundColor: 'BCBCBC'
      },
      {
        groupKey: 'referenceGroup',
        groupMethods: {
          getColumnTitle: (surveys) => {
            return [
              `Referentiegroep (deelname ${getSchoolCount(surveys.referenceGroup)} instellingen met ${getParticipantCount(surveys.referenceGroup)} deelnemers)`,
              ...emptyCellsArray(9)
            ];
          },
          getColumnSecondLevelTitle: () => averageAndStDevHeaders,
          getPercentagesCells: (opts) => getAverageAndStandardDeviationCells(opts.question, opts.surveys)
        },
        columnsForResultsNeeded: 10,
        columnsBackgroundColor: '979797'
      },
      {
        groupKey: 'vlaanderenGroup',
        groupMethods: {
          getColumnTitle: (surveys) => {
            return [
              `Vlaanderen (deelname ${getSchoolCount(surveys.vlaanderenGroup)} instellingen met ${getParticipantCount(surveys.vlaanderenGroup)} deelnemers)`,
              ...emptyCellsArray(9)
            ];
          },
          getColumnSecondLevelTitle: () => averageAndStDevHeaders,
          getPercentagesCells: (opts) => getAverageAndStandardDeviationCells(opts.question, opts.surveys)
        },
        columnsForResultsNeeded: 10,
        columnsBackgroundColor: '7C7C7C'
      }
    ];

    const { apiHelpers } = dataHelpers;

    const service = {};

    const getSchoolInfo = (school, totalAnswers, totalParticipants, reportType, group,
      situation) => {
      const address = school.locations.length > 0 ? school.locations.map(l => l.display).join(' - ') : school.address;
      return [
        [reportType],
        [school.name + ', ' + address],
        ['Aantal deelnemers: ' + totalAnswers + '/' + totalParticipants],
        [constants.reportSituations.find(s => s.key === situation).value],
        [group ? group.name : 'Alle groepen'],
        []
      ];
    };

    /**
     * For every survey fill the percentages for each of the questions grouped by component
     * using all the answers given.
     */
    function fillSurveysWithAnswersAndPercentages(surveys, allAnswers, group = {}, situation) {
      const filledSurveys = [];

      // add the group type of reference group for each answer (needed later to filter)
      allAnswers = allAnswers.map(a => {
        const survey = surveys.find(s => s.$$meta.permalink === a.survey.href);
        const surveyGroup = survey.groups.find(g => g.key === a.group);
        a.$$groupType = surveyGroup ? surveyGroup.type : null;
        return a;
      });

      surveys.forEach(survey => {
        if (!survey.answers) {
          // only answers of the given survey and group
          survey.answers = allAnswers
            .filter(a => a.survey.href === survey.$$meta.permalink
              && (!group.key || a.$$groupType === group.type));

          if (survey.answers.length > 0) {
            survey.components = Reports.generateComponentsStructure(group.type, survey.key);

            Reports.generatePercentageBoxes(
              Utils.filterAnswersByGroupType(survey.answers, group.type),
              survey.components,
              null,
              { situation: situation, skipInfo: true, excelReport: true }
            );

            console.log('Percentages to survey:', survey, survey.components[0].questions[0]);

            filledSurveys.push(survey);
          }
        }
      });

      console.log('Filled surveys:', filledSurveys);
    }


    /**
     * This method return all the related schools for a given school.
     * We say that a school is "related" to another one if they belong to the
     * same "governingInstitution"
     * @param {*} surveySchool
     */
    const getSchoolsHrefsOfSameGovInst = async (surveySchool) => {
      // We find the governingInstitution
      const [govInstitution] = await apiHelpers.SAMAPI.getGovInstsForGivenSchoolsSAMHrefs([surveySchool.$$meta.permalink]);
      const govInstitutionHref = govInstitution.$$meta.permalink;

      // We get all the schoolsHrefs belonging to that governingInstitution
      const govInstSchoolsHrefs = await apiHelpers.SAMAPI.getGovInstSchoolsHrefs(govInstitutionHref);

      return govInstSchoolsHrefs;
    };

    /**
     * This method returns the ENDED surveys WITH ANSWERS that belong to the
     * list of schools it receives
     * @param {*} allSurveysData
     * @param {*} schoolsHrefsOfSameGovInst
     * @returns
     */
    const getEndedSurveys = (allSurveysData, schoolsHrefsOfSameGovInst) => {
      const schoolsHrefsOfSameGovInstSet = new Set(schoolsHrefsOfSameGovInst);
      const allSurveys = allSurveysData.surveys;
      return allSurveys.filter(s => {
        const surveySchoolHref = `/sam${s.school.href}`;

        const hasAnswers = s.answers.length > 0;
        const isAlreadyEnded = dateUtils.isBefore(s.enddate, dateUtils.getNow());
        return schoolsHrefsOfSameGovInstSet.has(surveySchoolHref) && hasAnswers && isAlreadyEnded;
      });
    };

    /**
     * This method returns the campus href for a given survey
     * @param {*} survey
     * @returns
     */
    const getSurveyCampusHref = (survey) => {
      return (survey.location && survey.location.href) || survey.school.href;
    };

    /**
     * This method return the existing surveys for a given schoolsHrefs. It returns
     * only one survey for each school (you can find more information about what are
     * the rules we follow to select that in the function inner-comments)
     * @param {*} currentSurvey
     * @param {*} allSurveysData
     * @param {*} schoolsHrefsOfSameGovInst
     * @returns
     */
    const getschoolbestuurData = (currentSurvey, allSurveysData, schoolsHrefsOfSameGovInst) => {
      const endedSurveys = getEndedSurveys(allSurveysData, schoolsHrefsOfSameGovInst);

      const schoolbestuurData = endedSurveys.reduce((accumulatedValues, currentValue) => {
        // We get the href of the campus which will be the key in our "Set"
        const surveyCampusHref = getSurveyCampusHref(currentValue);
        // If the key of the survey is the same as the survey that the user is downloading
        // the results of we save it right away
        const isCurrentSurvey = currentSurvey.key === currentValue.key;
        if (isCurrentSurvey) {
          accumulatedValues.set(surveyCampusHref, currentValue);
          return accumulatedValues;
        }

        // If we already have the key in our "Set" and it is not the currentSurvey (the survey
        // that the user is downloading the results) we save the most recent one in the "Set"
        const previousValueOnMap = accumulatedValues.get(surveyCampusHref);
        if (previousValueOnMap) {
          const isPreviouslyCurrentSurvey = currentSurvey.key === previousValueOnMap.key;
          if (isPreviouslyCurrentSurvey) {
            return accumulatedValues;
          }

          const mostRecentOne = dateUtils.isAfter(previousValueOnMap.enddate, currentValue.enddate) ? previousValueOnMap : currentValue;
          accumulatedValues.set(surveyCampusHref, mostRecentOne);
          return accumulatedValues;
        }

        accumulatedValues.set(surveyCampusHref, currentValue);
        return accumulatedValues;
      }, new Map());

      return schoolbestuurData && [...schoolbestuurData.values()];
    };


    const getReferenceGroupSurveyData = (survey, allSurveysData) => {
      // filter only surveys with the same level of the current one
      return allSurveysData.surveys
        .filter(s => s.level === survey.level
        && s.answers.length > 0
        && dateUtils.isBefore(s.enddate, dateUtils.getNow()));
    };


    const getVlaanderenSurveyData = (allSurveysData) => {
      // all ended surveys with answers and are not governing institutions
      return allSurveysData.surveys.filter(s => s.answers.length > 0
        && dateUtils.isBefore(s.enddate, dateUtils.getNow())
        && (!s.level || s.level !== 'GOVERNING_INSTITUTION'));
    };

    function getStatementsRows(questions, components, situation, surveys, xlsxDataGroupsToUse) {
      const data = [];

      if (!components) {
        components = [{ questions }];
      }

      const emptyCellsToFill = xlsxDataGroupsToUse.reduce((prev, curr) => prev + curr.columnsForResultsNeeded, 0);

      components.forEach(component => {
        // add a row with component title (if it has one)
        if (component.title) {
          data.push([component.title, ...emptyCellsArray(emptyCellsToFill)]);
        }

        const sortedQuestions = Utils.sortQuestionsByTitle(component.questions);
        // add rows for each question in the component
        sortedQuestions.forEach(question => {
          const opts = { question, situation };
          data.push([
            question.description,
            ...xlsxDataGroupsToUse.map(dataGroup => {
              return dataGroup.groupMethods.getPercentagesCells({
                ...opts,
                surveys: surveys[dataGroup.groupKey]
              });
            }).flat()
          ]);
        });
      });

      return data;
    }

    /**
     * This method return the survey groups that will be included in the XLXS report together with
     * the data of that group
     * @param {*} survey
     * @param {*} allSurveys
     * @returns
     */
    const getSurveyGroupsToInclude = async (survey, allSurveys) => {
      // Main conditions to show/hide groups
      // IMPORTANT: Every group could include extra conditions in the following code

      const includeSchoolbestuurColumn = survey.level !== 'GOVERNING_INSTITUTION';
      const includeReferenceGroupColumn = survey.level !== 'GOVERNING_INSTITUTION';

      let surveys = {
        vlaanderenGroup: getVlaanderenSurveyData(allSurveys)
      };

      if (includeSchoolbestuurColumn) {
        // we get the schools related to the survey school
        const surveySchool = Survey.getSchool();
        const schoolsHrefsOfSameGovInst = await getSchoolsHrefsOfSameGovInst(surveySchool);

        // we gather together the data belonging to the surveys of the related schools in order to put all of them together and be able to print it in the excel
        surveys = {
          ...surveys,
          schoolbestuurGroup: getschoolbestuurData(survey, allSurveys, schoolsHrefsOfSameGovInst)
        };
      }

      if (includeReferenceGroupColumn) {
        // we only include the referenceGroup if the group is greater than 6
        const referenceGroup = getReferenceGroupSurveyData(survey, allSurveys);

        if (referenceGroup.length > 6) {
          surveys = {
            ...surveys,
            referenceGroup
          };
        }
      }

      return surveys;
    };

    /**
     * CONCEPTS DEFINITION:
     *  - "currentSurvey" => when you find this word in the explanation I'm refering to the survey
     *    that the user is trying to download the results for
     *
     * This method generates the data to print in the excel.
     * The excel must have 4 blocks (according to Greet Van Hove):
     *  - First Block: It must include the results of the "currentSurvey"
     *  - Schoolbestuur: It must include the results of the surveys of the schools belonging to the same
     *    governingInstitution. For each school we will only return the most recent ended survey.
     *
     *    In exception to that, if the "currentSurvey" is not the most recent survey of the school
     *    we are processing we will return the "currentSurvey" instead of the most recent.
     *
     *    IMPORTANT!!! We don't make any differences here regarding the educationLevel (PRIMARY / SECONDARY / etc..)
     *  - Referentiegroep: It must include all the results of the surveys belonging to the same educationLevel than the school of the "currentSurvey"
     *  - Vlaanderen: It must include the data of all the schools that already ended the survey
     *
     * @param {*} survey
     * @param {*} totalAnswers
     * @param {*} totalParticipants
     * @param {*} questions
     * @param {*} components
     * @param {*} allSurveysData
     * @param {*} reportType
     * @param {*} group
     * @param {*} situation
     * @returns
     */
    service.getReportData = async (survey, totalAnswers, totalParticipants, questions, components,
      allSurveysData, reportType, group, situation) => {
      fillSurveysWithAnswersAndPercentages(
        allSurveysData.surveys,
        allSurveysData.answers,
        group,
        situation
      );

      const surveyGroupsToInclude = await getSurveyGroupsToInclude(survey, allSurveysData);

      const surveyGroupsToIncludeKeys = [
        'surveyGroup', // survey group is always included!!
        ...Object.keys(surveyGroupsToInclude)
      ];

      // we filter the groups we don't need to render
      const xlsxDataGroupsToUse = xlsxDataGroups.filter(x => surveyGroupsToIncludeKeys.includes(x.groupKey));

      return {
        xlsxData: [
          ...getSchoolInfo(survey.school.$$expanded, totalAnswers, totalParticipants,
            reportType, group, situation),
          ...[xlsxDataGroupsToUse.map(x => x.groupMethods.getColumnTitle(surveyGroupsToInclude)).flat()],
          ...[xlsxDataGroupsToUse.map(x => x.groupMethods.getColumnSecondLevelTitle()).flat()],
          ...getStatementsRows(questions, components, situation, surveyGroupsToInclude, xlsxDataGroupsToUse)
        ],
        surveyGroupsToInclude
      };
    };

    const renderBackground = (worksheet, initialCol, initialRow, amountOfCols, color) => {
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      // No.of rows (0-indexed rows, for x rows the result will be x-1, so we need the plus 1)
      const noRows = range.e.r + 1; // No.of rows

      for (let col = initialCol - 1; col < initialCol + amountOfCols - 1; col += 1) {
        for (let row = initialRow - 1; row < noRows; row += 1) {
          const cell = XLSX.utils.encode_cell({ c: col, r: row });
          worksheet[cell].s = Object.assign({}, worksheet[cell].s, {
            fill: {
              patternStyle: 'solid',
              fgColor: { rgb: 'FF' + color }
            }
          });
        }
      }
    };

    const renderResultGroupsBackgroundCells = (worksheet, includeColumns = {}) => {
      const {
        includeSchoolbestuurColumn,
        includeReferenceGroupColumn
      } = includeColumns;

      const groupsInitialColIndex = [2, 7, 17, 27];

      xlsxDataGroups.forEach(group => {
        const excludeSchoolbestuurGroup = group.groupKey === 'schoolbestuurGroup' && !includeSchoolbestuurColumn;
        const excludeReferenceGroup = group.groupKey === 'referenceGroup' && !includeReferenceGroupColumn;
        const skipGroup = excludeSchoolbestuurGroup || excludeReferenceGroup;

        if (!skipGroup) {
          const color = group.columnsBackgroundColor;
          const initialCol = groupsInitialColIndex.shift();
          renderBackground(worksheet, initialCol, 7, 10, color);
        }
      });
    };

    service.addStyles = (worksheet, surveyGroupsToIncludeKeys) => {
      const includeSchoolbestuurColumn = surveyGroupsToIncludeKeys.includes('schoolbestuurGroup');
      const includeReferenceGroupColumn = surveyGroupsToIncludeKeys.includes('referenceGroup');

      const fontBold = {
        bold: true
      };

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      // No. of cols (0-indexed columns, for x columns the result will be x-1, so we need the plus 1)
      const noCols = range.e.c + 1;

      const wscols = [
        { wch: 60 }
      ];
      worksheet['!cols'] = wscols;

      // this is not working when wirtting with xlsx-styles
      const wsrows = [
        { hpt: 25 },
        { hpt: 25 },
        {}, {}, {}, {},
        { hpt: 20 },
        { hpt: 150 }
      ];
      worksheet['!rows'] = wsrows;

      // first headers styles
      worksheet.A1.s = {
        font: {
          bold: true,
          italic: true,
          sz: 20
        }
      };
      worksheet.A2.s = {
        font: {
          italic: true,
          sz: 20
        }
      };
      for (let i = 3; i <= 5; i += 1) {
        worksheet['A' + i].s = {
          font: {
            italic: true
          }
        };
      }

      // special header cells with size 16
      const font16Cells = ['B7', 'G7'];
      const nextFont16CellsToAdd = ['Q7', 'AA7'];
      if (includeSchoolbestuurColumn) { font16Cells.push(nextFont16CellsToAdd.shift()); }
      if (includeReferenceGroupColumn) { font16Cells.push(nextFont16CellsToAdd.shift()); }

      font16Cells.forEach(c => {
        worksheet[c].s = {
          font: {
            bold: true,
            sz: 16
          }
        };
      });

      // row 8 is bold
      for (let col = 0; col < noCols; col += 1) {
        const cell = XLSX.utils.encode_cell({ c: col, r: 7 });
        worksheet[cell].s = {
          font: fontBold,
          alignment: {
            wrapText: true
          }
        };
      }

      // background color different for each type of result
      renderResultGroupsBackgroundCells(worksheet, {
        includeSchoolbestuurColumn,
        includeReferenceGroupColumn
      });

      // merge some cells
      worksheet['!merges'] = [
        { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
        { s: { r: 6, c: 1 }, e: { r: 6, c: 5 } },
        { s: { r: 6, c: 6 }, e: { r: 6, c: 15 } },
        { s: { r: 6, c: 16 }, e: { r: 6, c: 25 } },
        { s: { r: 6, c: 26 }, e: { r: 6, c: 35 } }
      ];
    };

    service.write = (workbook, filename) => {
      const xlsxStyles = require('xlsx-styles');

      const wbout = xlsxStyles.write(workbook, {
        type: 'binary',
        bookType: 'xlsx',
        bookSST: true,
        file: filename
      });

      // eslint-disable-next-line no-inner-declarations
      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
      }

      /* the saveAs call downloads a file on the local machine */
      const saveAs = require('file-saver');
      saveAs(new Blob([s2ab(wbout)], { type: '' }), filename);
    };

    return service;
  }];
