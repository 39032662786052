// @deprecated
module.exports = ['$http', '$q', '$notification', '$timeout', 'settings', 'constants', 'Utils', 'sriClient', 'dateUtils',
  function($http, $q, $notification, $timeout, settings, constants, Utils, sriClient, dateUtils) { //eslint-disable-line
    'use strict';
    var that = {};

    var handleErrors = function (errorBody, status, url, cancelPromise) {
      var cancelled = false;
      if (cancelPromise) {
        cancelPromise.then(function () {
          cancelled = true;
        });
      }
      if (!errorBody) {
        errorBody = {};
      }
      if (!status) {
        errorBody.status = 0;
      } else {
        errorBody.status = status;
      }
      $timeout(function () {
        if (!cancelled) {
          $notification.handleErrors(errorBody, {href: url, $$accessDeniedReason: url + ' te raadplagen',
                                                 message: errorBody.message});
        }
      }, 1);
    };

    function removeNulls(obj) {
      var k;

      for (k in obj) {
        if (obj.hasOwnProperty(k) && obj[k] === null) {
          delete obj[k];
        }
      }

      return obj;
    }

    /**
     * Get the directors of the given school.
     * Returns a list of /persons
     * 
     * @param {*} schoolHref 
     */
    that.getSchoolDirectors = function(school) {

      const d = $q.defer();

      let today = Utils.dateToString(new Date());

      sriClient.getAll('/responsibilities?organisations=/schools/' + school.key + 
                       '&positions=' + constants.directorPosition.href +
                       '&date=' + today).then(results => {
        const promises = results.map(responsibility => sriClient.get(responsibility.person.href));
        
        $q.all(promises).then((persons) => {

          persons = persons.map(p => {
            p.position = constants.directorPosition.name;
            return p;
          })
          d.resolve(persons);
        });
      });

      return d.promise;
    }

    /**
     * Get the chairmans of the given school. It looks in the governing instittution of the scghool those
     * persons with a position 'voorzitter'
     * Returns a list of /persons
     * 
     * @param {*} schoolHref 
     */
    that.getSchoolChairmans = function(school) {

      const d = $q.defer();

      let today = Utils.dateToString(new Date());

      // get governing instiution of the school
      let activeGoverningInsitutions = dateUtils.getActiveResources(school.$$governingInstitutions);

      if (activeGoverningInsitutions.length > 0) {

        sriClient.getAll('/responsibilities?organisations=' + activeGoverningInsitutions[0].href + 
                         '&positions=' + constants.chairmanPosition.href +
                         '&date=' + today).then(results => {
          const promises = results.map(responsibility => sriClient.get(responsibility.person.href));
          
          $q.all(promises).then((persons) => {
  
            persons = persons.map(p => {
              p.position = constants.chairmanPosition.name;
              return p;
            })
            d.resolve(persons);
          });
        });
      } else {
        d.resolve([]);
      }

      return d.promise;
    }

    that.getResource = function (href, params, ownErrorHandling, useCache) {
      params = params || {};
      var d = $q.defer();
      var api = settings.apisAndUrls.api;

      $http({
        method: 'GET',
        url: api + href,
        params: params,
        cache: useCache
      }).success(function (resp) {
        d.resolve(resp);
      }).error(function (error, statusCode) {
        if (!ownErrorHandling) {
          handleErrors(error, statusCode, href);
        } else if (error) {
          error.status = statusCode;
        } else {
          error = {status: statusCode};
        }
        d.reject(error);
      });
      return d.promise;
    };

    var getAllFromResults = function(data, getExpandedResources, useCache) {
      var defer = $q.defer();
      var i = 0;
      var results = [];
      if (getExpandedResources) {
        for (; i < data.results.length; i++) {
          results.push(data.results[i].$$expanded);
        }
      } else {
        results = data.results;
      }

      if (data.$$meta.next) {
        that.getResource(data.$$meta.next, null, null, useCache).then(function (next) {
          getAllFromResults(next, getExpandedResources).then(function (nextResults) {
            results = results.concat(nextResults);
            defer.resolve(results);
          });
        });
      } else {
        defer.resolve(results);
      }

      return defer.promise;
    };

    that.getResources = function (url, params, cancelPromise, useCache) {
      var d = $q.defer();
      // console.log('GET resources', url); //eslint-disable-line
      var api = settings.apisAndUrls.api;

      $('#loader-box').show(); //eslint-disable-line
      $http({
        method: 'GET',
        url: api + url,
        params: params,
        cache: useCache,
        timeout: cancelPromise
      }).success(function(resp) {
        var results = [];
        if (params && params.expand && params.expand === 'NONE') {
          results = resp.results;
        } else {
          results = resp.results.map(function(result) {
            return result.$$expanded;
          });
        }
        d.resolve({results: results, meta: resp.$$meta});
      }).error(function(error, statusCode) {
        handleErrors(error, statusCode, url, cancelPromise);
        d.reject(error);
      });

      return d.promise;
    };

    that.getAllResources = function (url, params, cancelPromise, useCache) {
      var d = $q.defer();
      var api = url.indexOf('/content') !== -1 || url.indexOf('/relations') !== -1
          ? settings.apisAndUrls.contentApi : settings.apisAndUrls.api;

      $('#loader-box').show(); //eslint-disable-line
      $http({
        method: 'GET',
        url: api + url,
        params: params,
        timeout: cancelPromise
      }).success(function(resp) {
        var getExpandedResource = !(params && params.expand && params.expand === 'NONE');
        getAllFromResults(resp, getExpandedResource, useCache).then(function (allResults) {
          d.resolve({results: allResults, meta: resp.$$meta});
        });
      }).error(function(error, statusCode) {
        handleErrors(error, statusCode, url);
        d.reject(error);
      });

      return d.promise;
    };

    that.getAllResourcesConcurrent = function (url, params, offset, times, cancelPromise, useCache) {
      var d = $q.defer();
      var api = url.indexOf('/content') !== -1 || url.indexOf('/relations') !== -1
          ? settings.apisAndUrls.contentApi : settings.apisAndUrls.api;

      $('#loader-box').show(); //eslint-disable-line
      var promises = [];
      for (var i = 0; i < times; i++) {
        var offsetCondition = '&offset=' + (offset * i);
        promises.push(that.getResources(url + offsetCondition, params));
      }

      $q.all(promises).then(function(results) {
        var resources = [];
        results.map(function(response) {
          resources = resources.concat(response.results);
        });
        d.resolve({results: resources});
      });

      return d.promise;
    };

    that.createResources = function(resourceName, batch) {
      var defer = $q.defer();
      var i = 0;
      $('#loader-box').show(); //eslint-disable-line

      $http({
        method: 'POST',
        url: settings.apisAndUrls.api + '/' + resourceName + '/batch',
        data: batch,
        contentType: 'application/json',
        dataType: 'json'
      }).success(function(data, status) {
        var resp = {
          status: status
        };
        defer.resolve(resp);

      }).error(function(resp, status) {
        if (!status) {
          resp.status = 0;
        } else {
          resp.status = status;
          if (resp.constructor === Array) {
            for (i = 0; i < batch.length; i++) {
              if (resp[i].body) {
                resp[i].body.document = batch[i].body;
              }
            }
          } else {
            handleErrors(resp, status, batch[0].body.$$meta.permalink);
          }
        }
        defer.resolve(resp);
      });

      return defer.promise;
    };

    that.putResource = function (resource) {
      var defer = $q.defer();
      $('#loader-box').show(); //eslint-disable-line

      removeNulls(resource);

      $http({
        method: 'PUT',
        url: settings.apisAndUrls.contentApi + resource.$$meta.permalink, //TODO replace .contentApi for .api
        data: resource,
        contentType: 'application/json',
        dataType: 'json'
      }).success(function(data, status) {
        var resp = {
          status: status,
          resource: resource
        };
        defer.resolve(resp);

      }).error(function(resp, status) {
        if (!status && resp) {
          resp.status = 0;
        } else if (!resp) {
          resp = 'No response';
        } else {
          resp.status = status;
          if (resp.errors) {
            resp.document = resource;
          }
        }
        defer.resolve(resp);
      });

      return defer.promise;
    };

    that.validateResource = function (path, resource) {
      var defer = $q.defer();
      $('#loader-box').show(); //eslint-disable-line

      removeNulls(resource);

      $http({
        method: 'POST',
        url: settings.apisAndUrls.contentApi + path, //TODO replace .contentApi for .api
        data: resource,
        contentType: 'application/json',
        dataType: 'json'
      }).success(function(data, status) {
        var resp = {
          status: status,
          resource: resource
        };
        defer.resolve(resp);

      }).error(function(resp, status) {
        if (!status && resp) {
          resp.status = 0;
        } else if (!resp) {
          resp = 'No response';
        } else {
          resp.status = status;
          if (resp.errors) {
            resp.document = resource;
          }
        }
        defer.resolve(resp);
      });

      return defer.promise;
    };

    that.deleteResource = function (uri) {
      var defer = $q.defer();
      $('#loader-box').show(); //eslint-disable-line

      $http({
        method: 'DELETE',
        url: settings.apisAndUrls.contentApi + uri,
        dataType: 'json'
      }).success(function(data, status) {
        var resp = {
          status: status
        };
        defer.resolve(resp);
      }).error(function(resp, status) {
        resp.status = status;
        defer.resolve(resp);
      });

      return defer.promise;
    };

    that.getMe = function() {
      var d = $q.defer();
      $http.get(settings.oauth.me, { withCredentials: true, cache: true }).success(function (me) {
        that.getResource('/persons/' + me.uuid, null, null, true).then(function (person) {
          d.resolve(person);
        });
      }).error(function(error) {
        d.reject(error);
      });
      return d.promise;
    };

    that.searchPersons = function (query, limit, cancelPromise) {
      var words;
      if (query && query !== '') {
        words = query.split(' ').join(' ');
      }
      var params = {
        q: words,
        limit: limit,
        expand: 'FULL'
      };
      return that.getResources('/persons', params, cancelPromise);
    };

    that.log = function (message, type) {
      $http({
        method: 'PUT',
        url: '/log',
        data: {message: message, type: type},
        contentType: 'application/json',
        dataType: 'json'
      });
    };

    that.audit = function (resource, type, component, operation, me) {
      var defer = $q.defer();
      $('#loader-box').show(); //eslint-disable-line

      var auditResource = {
        key: Utils.getUUID(),
        timestamp: (new Date()).toISOString(),
        person: me,
        component: component,
        operation: operation,
        type: type,
        resource: resource.$$meta.permalink,
        document: resource};

      $http({
        method: 'PUT',
        url: settings.apisAndUrls.auditApi + '/versions/' + auditResource.key,
        data: auditResource,
        contentType: 'application/json',
        dataType: 'json'
      }).success(function(data, status) {
        var resp = {
          status: status
        };
        defer.resolve(resp);
      }).error(function(resp, status) {
        if (!status && resp) {
          resp.status = 0;
        } else if (!resp) {
          resp = 'No response';
        } else {
          resp.status = status;
          if (resp.errors) {
            resp.document = resource;
          }
        }
        defer.resolve(resp);
      });

      return defer.promise;
    };

    return that;
}];
