/**
 * Belgium-Dutch translation for bootstrap-datepicker
 * Julien Poulin <poulin_julien@hotmail.com>
 */
;(function($){
    $.fn.datepicker.dates['nl-BE'] = {
      days: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
      daysShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
      daysMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
      months: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
      monthsShort: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
      today: "Vandaag",
      monthsTitle: "Maanden",
      clear: "Leegmaken",
      weekStart: 1,
      format: "dd/mm/yyyy"
    };
  }(jQuery));