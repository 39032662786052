/* @ngInject */
class TestI {

  constructor($rootScope, $scope, $location, dateUtils) {
    console.log('Test component const separate:',$rootScope);
    dateUtils.stripTime('2018-08-10');
  }  
}


module.exports = {
  template: require('./test-inject.html'),
  controllerAs: 'ctrl',
  controller: TestI
};