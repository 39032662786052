require('./css/backToSurveys.scss');

class BackToSurveys {

    constructor($scope){
      this.$scope = $scope;
    }
  
    // injection here
    static get $inject() {
      return [
        '$scope'
      ];
    }
    
    
    $onInit() {
      return (async () => {
      })();
    }

    onBack() {
      this.$scope.$emit('back_to_surveys');
    }
  
    $onChanges(changes) {
    }
  }
  
  
  module.exports = {
    template: require('./templates/backToSurveys.html'),
    controllerAs: 'ctrl',
    controller: BackToSurveys,
    bindings: {
    }
  };